<template>
  <v-dialog v-model="modalOpened" width="500">
    <v-card class="apple-add-to-home-screen-modal">
      <v-card-title>
        <v-spacer></v-spacer>
        <v-btn icon color="primary" @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <div class="modal-wrapper">
          <div class="main-content">
            <img
              class="app-logo"
              src="@/assets/img/Piletivahetuse_logo_valge.svg"
              alt=""
            />
            <h3 class="title text-center my-2">Install {{ appTitle }}</h3>
            <p class="description text-center my-2">
              Install this application to your home screen for quickly and easy
              access when you're on the go.
            </p>
          </div>

          <div class="footer">
            Just tap
            <img
              class="apple-navigation-action-img mx-2"
              src="@/assets/img/apple-navigation-action.png"
              alt=""
            />then 'Add to Home Screen' &nbsp; <v-icon>mdi-plus-box</v-icon>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {
      modalOpened: true
    }
  },
  computed: mapState('app', ['appTitle'])
}
</script>

<style lang="scss" scoped>
.apple-add-to-home-screen-modal {
  .main-content {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .app-logo {
      max-width: 50px;
      margin-bottom: 10px;
    }
  }
  .footer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    background-color: #f9f9f9;
    font-size: 12px;

    .apple-navigation-action-img {
      max-width: 15px;
    }
  }
}
</style>
