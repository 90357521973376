import Vue from 'vue'

export default {
  /**
   * Sets events Array (event can be set with setEvent function)
   * @param {Array} events - events Array
   */
  setEvents: (state, events) => {
    state.events = events
  },
  /**
   * Sets event Object
   * @param {Object} event - event Object
   */
  setEvent: (state, event) => {
    state.event = event
  },
  /**
   * Sets tickets Array
   * @param {Array} tickets - tickets Array
   */
  setTickets: (state, tickets) => {
    state.tickets = tickets
  },
  /**
   * Sets tickets Array (usetTicket can be set with setUserTicket function)
   * @param {Array} userTickets - userTickets Array
   */
  setUserTickets: (state, userTickets) => {
    state.userTickets = userTickets
  },
  /**
   * Set uploadedTickets Any
   * @param {Any} value -Any value
   */
  setUploadedTickets: (state, value) => {
    state.uploadedTickets = value
  },
  /**
   * Adds ticket to uploadedTickets Array
   * @param {Object} ticket - uploaded ticket Object
   */
  addUploadedTicket: (state, ticket) => {
    state.uploadedTickets.push(ticket)
  },
  /**
   * Sets last visible event, to be able to query more events later (default limit is 10)
   * @param {Object} event - event Object
   */
  setLastVisibleEvent: (state, event) => {
    if (state.events) {
      state.lastVisibleEvent = event[event.length - 1]
    }
  },
  /**
   * Sets events sort order
   * @param {Object} value - event sort order Object, eg. ({ title: 'Desc', value: 'desc', sortBy: 'createTimestamp' })
   */
  setSelectedSortOrder: (state, value) => {
    state.selectedSortOrder = value
  },

  /**
   * Adds user ticket to userTickets Array (userTickets can be set with setUserTickets function)
   * @param {Object} ticket - ticket Object
   */
  addUserTicket: (state, ticket) => {
    const exists = state.userTickets.some(elem => {
      return elem.id === ticket.id
    })
    if (!exists) {
      state.userTickets.push(ticket)
    }
  },
  /**
   * Deletes user ticket (this functions sets ticket status to inactive)
   * @param {String} ticketId - ticket id (ticket.id)
   */
  deleteTicket: (state, ticketId) => {
    const ticketInAction = state.userTickets.find(post => post.id === ticketId)
    Vue.set(ticketInAction, 'status', 'inactive')
  },
  /**
   * Activates user ticket (this functions sets ticket status to active)
   * @param {String} ticketId - ticket id (ticket.id)
   */
  activateTicket: (state, ticketId) => {
    const ticketInAction = state.userTickets.find(post => post.id === ticketId)
    Vue.set(ticketInAction, 'status', 'active')
  },
  /**
   * Deactivates user ticket (this functions sets ticket status to deactivated)
   * @param {String} ticketId - ticket id (ticket.id)
   */
  deactivateTicket: (state, ticketId) => {
    const ticketInAction = state.userTickets.find(post => post.id === ticketId)
    Vue.set(ticketInAction, 'status', 'deactivated')
  },
  /**
   * Marks user ticket as sold (this functions sets ticket status to sold)
   * @param {String} ticketId - ticket id (ticket.id)
   */
  markAsSold: (state, ticketId) => {
    const userTicket = state.userTickets.find(ticket => ticket.id === ticketId)
    if (userTicket) {
      Vue.set(userTicket, 'status', 'sold')
    }
  },
  /**
   * Sets showSoldItems Array (used to toggle between sold and not sold userTickets in UserTickets view)
   * @param {Boolean} value - true or false (default if false)
   */
  setShowSoldItems: (state, value) => {
    state.showSoldItems = value
  },
  /**
   * Clears events Array (used to clear events, eg. after logout)
   */
  clearEvents: state => {
    if (state.events && state.events.length > 0) {
      state.events.length = []
    }
  },
  /**
   * Clears tickets Array (used to clear tickets, eg. after logout)
   */
  clearUserTickets: state => {
    if (state.userTicket && state.userTickets.length > 0) {
      state.userTickets.length = []
    }
  },
  /**
   * Updates ticket price
   * @param {Object} data - Object including ticketId and newPrice fields
   */
  updateTicketPrice: (state, data) => {
    if (!data.newPrice) return
    const ticketInUserTickets = state.userTickets.find(ticket => {
      return ticket.id === data.ticketId
    })
    Vue.set(ticketInUserTickets, 'price', data.newPrice)
  },
  /**
   * Sets steppetStep (used under add-ticket view components)
   * @param {Number} step - step Number
   */
  setStepperStep: (state, step) => {
    if (window) window.scrollTo(0, 0)
    state.stepperStep = step
  },
  /**
   * Sets selectedEvent Object (used under add-ticket view components)
   * @param {Object} selectedEvent - selectedEvent Object
   */
  setSelectedEvent: (state, selectedEvent) => {
    state.selectedEvent = selectedEvent
  },
  /**
   * Sets ddedNewEventFromDialog (used under add-ticket view components)
   * @param {Boolean} value - true or false (default is false)
   */
  setAddedNewEventFromDialog: (state, value) => {
    state.addedNewEventFromDialog = value
  },
  /**
   * Sets ticket.salesId (used under add-ticket view components)
   * @param {String} salesId - salesId String
   */
  setTicketSalesId: (state, salesId) => {
    state.ticketSalesId = salesId
  },
  /**
   * Sets ticket ticketType (used under add-ticket view components)
   * @param {String} ticketType - ticketType String
   */
  setTicketType: (state, ticketType) => {
    state.ticketType = ticketType
  },
  /**
   * Sets ticket.swaptDetais (used under add-ticket view components)
   * @param {String} swapDetails - swapDetails String
   */
  setSwapDetails: (state, swapDetails) => {
    state.swapDetails = swapDetails
  },
  /**
   * Sets ticket.seat (used under add-ticket view components)
   * @param {String} value - value String
   */
  setTicketSeat: (state, value) => {
    state.ticketSeat = value
  },
  /**
   * Sets selected price (used under add-ticket view components, used if userAddedTickets.length <= 1)
   * @param {Number} value - value String
   */
  setSelectedPrice: (state, value) => {
    state.selectedPrice = value
  },
  /**
   * Sets allowSwap (used under add-ticket view components)
   * @param {Boolean} value - true or false (default is false)
   */
  setAllowSwap: (state, value) => {
    state.allowSwap = value
  },
  /**
   * Sets sellAsPatch (used under add-ticket view components)
   * @param {Boolean} value - true or false (default is false)
   */
  setSellAsPatch: (state, value) => {
    state.sellAsPatch = value
  },
  /**
   * Adds new ticket to userAddedTickets Array
   * @param {Object} ticket - ticket Object
   */
  addNewTicketsToUserAddedTicketsArray: (state, ticket) => {
    const exists = state.userAddedTickets.some(userAddedTicket => {
      return userAddedTicket.id === ticket.ticketId
    })
    if (!exists) {
      state.userAddedTickets.push(ticket)
    }
  },
  /**
   * Sets userAddedTickets Array
   * @param {Any} value - value Any
   */
  setUserAddedTickets: (state, value) => {
    state.userAddedTickets = value
  },
  /**
   * Sets user added ticket Object
   * @param {Object} ticket - ticket Object
   */
  setUserAddedTicket: (state, data) => {
    const ticketInUserAddedTickets = state.userAddedTickets.find(
      userAddedTicket => {
        return userAddedTicket.id === data.ticketId
      }
    )
    Vue.set(ticketInUserAddedTickets, data.key, data.value)
  },
  /**
   * Removes ticket from userAddedTickets Array
   * @param {Number} index - ticket ticketId Number in userAddedTickets
   */
  removeUserAddedTicket: (state, ticketId) => {
    const index = state.userAddedTickets.findIndex(
      ticket => ticket.id === ticketId
    )
    state.userAddedTickets.splice(index, 1)
  },
  /**
   * Removes ticket from uploadedTickets Array
   * @param {Number} index - ticket index Number in uploadedTickets
   */
  removeUploadedTicket: (state, data) => {
    const index = state.uploadedTickets.findIndex(
      ticket => ticket.index === data.index
    )
    state.uploadedTickets.splice(index, 1)
    if (state.uploadedTickets.length === 1) {
      state.uploadedTickets = []
    }
  }
}
