<template>
  <v-snackbar :value="true" :timeout="0">
    <span v-if="refreshingApp">Uuenenud sisu saadaval...</span>
    <span v-else>Uuenenud sisu saadaval. Palun</span>
    <v-btn color="accent" text @click="$emit('refresh')">
      Lae uuesti
    </v-btn>
  </v-snackbar>
</template>

<script>
export default {
  props: {
    refreshingApp: { type: Boolean, default: false, required: true }
  }
}
</script>
