import TokensDB from '@/firebase/tokens-db'

export default {
  /**
   * Closes "add to home screen" modal for apple
   */
  closeAddToHomeScreenModalForApple: async ({ commit }) => {
    localStorage.setItem('addToHomeIosPromptLastDate', Date.now())
    commit('setShowAddToHomeScreenModalForApple', false)
  },

  /**
   * Trigger service worker skipWating so the new service worker can take over.
   * This will also trigger a window refresh (see /src/misc/register-service-worker.js)
   */
  serviceWorkerSkipWaiting({ state, commit }) {
    if (
      state.SWRegistrationForNewContent === null ||
      state.SWRegistrationForNewContent === undefined
    )
      return

    commit('setRefreshingApp', true)
    state.SWRegistrationForNewContent.waiting.postMessage('skipWaiting')
  },
  async removeToken({ commit, rootState }) {
    commit(
      'app/setLoading',
      { type: 'removeToken', state: true },
      { root: true }
    )
    commit('app/setError', null, { root: true })
    try {
      await new TokensDB().delete(rootState.authentication.user.id)
      window.localStorage.removeItem('notificationToken')
      commit('app/setError', null, { root: true })
      commit(
        'app/setLoading',
        { type: 'removeToken', state: false },
        { root: true }
      )
    } catch (error) {
      commit('app/setError', error, { root: true })
      commit(
        'app/setLoading',
        { type: 'removeToken', state: false },
        { root: true }
      )
    }
  },
  async saveToken({ commit, rootState }, notificationToken) {
    commit('app/setLoading', { type: 'saveToken', state: true }, { root: true })
    commit('app/setError', null, { root: true })
    try {
      const token = await new TokensDB().create(
        { token: notificationToken },
        rootState.authentication.user.id
      )
      window.localStorage.setItem('notificationToken', token.token)
      commit(
        'app/setLoading',
        { type: 'saveToken', state: false },
        { root: true }
      )
      commit('app/setError', null, { root: true })
    } catch (error) {
      commit(
        'app/setLoading',
        { type: 'saveToken', state: false },
        { root: true }
      )
      commit('app/setError', error, { root: true })
    }
  }
}
