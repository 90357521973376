<template>
  <div class="overflow-x-hidden">
    <v-overlay :value="loading.state" color="rgba(255, 223, 223,1)">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </v-overlay>
    <v-container grid-list-md text-xs-center row>
      <v-flex xs12 class="d-flex align-items-end py-0">
        <v-spacer></v-spacer>
      </v-flex>
      <v-layout row wrap>
        <v-flex xs12 sm4 md5 class="py-0">
          <skeleton-box
            v-if="loading.state === true && !event.globalEventId"
            :width="$vuetify.breakpoint.smAndUp ? '432px' : '100%'"
            height="626px"
            class="mt-5 main-border-radius"
          />
          <v-img
            v-else-if="event.globalEventId"
            class="primary-shadow main-border-radius"
            :src="
              event.globalEventId || event.images.length > 0
                ? `https://firebasestorage.googleapis.com/v0/b/piletivahetus-develop.appspot.com/o/events%2Fjpg%2Fg_e_5h4234h${event.globalEventId}h4234h.jpg?alt=media`
                : require('@/assets/img/placeholder_2.png')
            "
            :lazy-src="
              `https://firebasestorage.googleapis.com/v0/b/piletivahetus-develop.appspot.com/o/events%2Fjpg%2Fg_e_5h4234h${event.globalEventId}h4234h.jpg?alt=media`
            "
            width="432px"
          >
            <template v-if="loading.state === true" v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-flex>
        <v-flex xs12 md7 sm7 class=" pl-sm-6 pl-0 pl-md-5">
          <div class="d-flex fill-height">
            <v-row align="center" justify="center">
              <v-col>
                <skeleton-box
                  v-if="loading.state === true"
                  width="124px"
                  height="32px"
                />
                <span
                  v-else
                  class="d-flex align-center pt-2 primary--text text--darken-2"
                >
                  <v-icon class="primary--text text--darken-2 mr-1">
                    mdi-calendar
                  </v-icon>
                  {{ fullDateMonth(event.startDate) }}
                </span>
                <skeleton-box
                  v-if="loading.state === true"
                  width="100%"
                  height="40px"
                  class="mt-5"
                />
                <h1 v-else class="display-1 mt-5">
                  {{ shortenTheTitle(event.title) }}
                </h1>
                <skeleton-box
                  v-if="loading.state === true"
                  width="224px"
                  height="24px"
                  class="mt-9"
                />
                <div v-else class="mt-12 headline-2 primary--text">
                  <a
                    @click="
                      openMaps(`https://www.google.com/maps?q=${event.venue}`)
                    "
                  >
                    <v-icon class="primary--text">mdi-map-marker</v-icon>
                    {{ event.venue }}
                  </a>
                </div>
                <skeleton-box
                  v-if="loading.state === true"
                  :width="$vuetify.breakpoint.smAndUp ? '645px' : '100%'"
                  height="90px"
                  class="mt-9"
                />
                <div
                  v-else
                  class="d-block mt-9"
                  style="max-width: 645px; font-family: Dosis; font-size: 24px; line-height: 30px;"
                >
                  <!-- Lorem ipsum dolor sit amet, consectetur adipiscing elit. At
                  cursus quis id volutpat. Tortor, placerat orci rutrum sit. Ac
                  nullam vulputate mi nunc luctus ultrices. -->
                </div>
                <div v-if="loading.state === false" class="mt-10">
                  <v-row align="center" justify="center">
                    <v-col xs6 class="px-0">
                      <div class="d-block font-weight-medium secondary--text">
                        Parima hinnaga pilet
                      </div>
                      <div class="d-block primary--text">
                        {{ bestPriceTicket().sellerFirstName }}
                      </div>
                      <span
                        v-if="bestPriceTicket().allowSwap"
                        class="d-block mt-6 primary--text font-weight-medium"
                      >
                        On valmis vahetuseks
                      </span>
                    </v-col>
                    <v-col class="d-flex justify-space-between flex-wrap">
                      <div class="secondary--text mr-12">
                        <span class="subtitle-2 font-weight-light mr-2"
                          >alates</span
                        >
                        <span class="display-1 price"
                          >{{ bestPriceTicket().price }}<span>€</span></span
                        >
                      </div>
                      <!-- <v-btn color="primary darken-3" width="152px">Osta</v-btn> -->
                    </v-col>
                  </v-row>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-flex>
        <br />
      </v-layout>
      <v-layout row wrap mt-12>
        <v-flex xs12>
          <v-card-actions>
            <span class="display-1 font-weight-medium">
              {{
                tickets.length === 1
                  ? `${tickets.length} Pakkumine`
                  : `${tickets.length} Pakkumist`
              }}
            </span>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-flex>
      </v-layout>
      <v-layout row wrap mt-0 mt-md-12>
        <v-flex xs12>
          <ticket-list :event-id="id" />
        </v-flex>
      </v-layout>
      <venue-map-dialog
        :visible="venueMapDialog"
        :map-url="
          `https://www.google.com/maps?q=${event.venue}&amp;output=embed`
        "
        @close="venueMapDialog = false"
      />
    </v-container>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import SkeletonBox from '@/components/SkeletonBox'
// Mixins
import FormatTitle from '@/mixins/FormatTitle'
import FormatDate from '@/mixins/FormatDate'

export default {
  name: 'Event',
  components: {
    SkeletonBox,
    VenueMapDialog: () =>
      import(
        /* webpackChunkName: "VenueMapDialog" */ '@/components/dialogs/VenueMapDialog'
      ),
    TicketList: () =>
      import(
        /* webpackChunkName: "TicketList" */ '@/components/Event/TicketList'
      )
  },
  mixins: [FormatTitle, FormatDate],
  props: {
    id: String
  },
  data() {
    return {
      venueMapDialog: false
    }
  },
  computed: {
    ...mapGetters('app', ['loading']),
    ...mapGetters('events', ['tickets', 'event']),
    ...mapGetters('authentication', ['user', 'isAuthenticated'])
  },
  async mounted() {
    window.scrollTo(0, 185)
    await this.getEventById(this.id)
  },
  methods: {
    ...mapActions('events', ['getEventById']),
    bestPriceTicket() {
      if (this.tickets.length === 0) return 'Sellel üritusel piletid puuduvad'
      let lowestPriceTicket = this.tickets[0]
      this.tickets.forEach(ticket => {
        if (ticket.price < lowestPriceTicket.price) lowestPriceTicket = ticket
      })
      return lowestPriceTicket
    },
    openMaps() {
      this.venueMapDialog = true
    }
  }
}
</script>
<style lang="scss" scoped>
.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  cursor: default;
}
</style>
