export default {
  showSoldItems: state => state.showSoldItems,
  orders: state => state.orders,
  pinnedPosts: state => state.pinnedPosts,
  events(state, _, rootState) {
    const { events } = state
    const { searchString } = rootState.app
    if (events.length > 0) {
      return events

        .filter(
          event =>
            event.title &&
            event.title.toLowerCase().indexOf(searchString.toLowerCase()) >=
              0 &&
            event.status !== 'inactive'
        )
        .sort((a, b) => {
          return (a.startDate < b.startDate) ? -1 : ((a.startDate > b.startDate) ? 1 : 0);
          // if (state.selectedSortOrder.value === 'desc') {
          //   return (
          //     b[state.selectedSortOrder.sortBy] -
          //     a[state.selectedSortOrder.sortBy]
          //   )
          // }
          // return (
          //   a[state.selectedSortOrder.sortBy] -
          //   b[state.selectedSortOrder.sortBy]
          // )
        })
    }
    return []
  },
  eventsWithTickets(state) {
    return state.events.filter(event => {
      return event.ticketsCount >= 1
    })
  },
  event: state => state.event,
  tickets: state => state.tickets,
  userTickets(state) {
    const { userTickets } = state
    const { showSoldItems } = state
    if (userTickets) {
      return userTickets.filter(item =>
        showSoldItems
          ? item.status === 'sold'
          : item.status === 'active' || item.status === 'deactivated'
      )
    }
    return []
  },
  subevents: state => state.subevents,
  posts(state, getters, rootState) {
    const { posts } = state
    const { searchString } = rootState.app
    const { selectedCategory } = rootState.app
    const { selectedLocation } = rootState.app
    if (getters.pinnedPosts && posts) {
      const notSeenPosts = posts.filter(
        post =>
          !getters.pinnedPosts.find(
            pinnedPost => pinnedPost.id === post.id && post.id === pinnedPost.id
          ) && !rootState.chats.chats.find(chat => chat.item.id === post.id)
      )
      if (selectedCategory !== 'All') {
        return notSeenPosts
          .sort((a, b) => {
            if (state.selectedSortOrder.value === 'desc') {
              return (
                b[state.selectedSortOrder.sortBy] -
                a[state.selectedSortOrder.sortBy]
              )
            }
            return (
              a[state.selectedSortOrder.sortBy] -
              b[state.selectedSortOrder.sortBy]
            )
          })
          .filter(
            item =>
              item.title.toLowerCase().indexOf(searchString.toLowerCase()) >=
                0 &&
              item.userId !== rootState.authentication.user.id &&
              item.category === selectedCategory &&
              item.isSold === false &&
              item.isActive === true &&
              item.location.city === selectedLocation.city
          )
      }
      return notSeenPosts
        .sort((a, b) => {
          if (state.selectedSortOrder.value === 'desc') {
            return (
              b[state.selectedSortOrder.sortBy] -
              a[state.selectedSortOrder.sortBy]
            )
          }
          return (
            a[state.selectedSortOrder.sortBy] -
            b[state.selectedSortOrder.sortBy]
          )
        })
        .filter(
          item =>
            item.title.toLowerCase().indexOf(searchString.toLowerCase()) >= 0 &&
            item.userId !== rootState.authentication.user.id &&
            item.isSold === false &&
            item.isActive === true &&
            item.location.city === selectedLocation.city
        )
    }
    return []
  },
  findSpecificPost: state => id => {
    if (state.posts) {
      return state.posts.filter(post => post.id === id)
    }
    return []
  },
  userPosts(state, getters, rootState) {
    const { userPosts } = state
    const { searchString } = rootState.app
    const { selectedCategory } = rootState.app
    const { showSoldItems } = state
    if (userPosts) {
      if (selectedCategory !== 'All') {
        return userPosts.filter(item => item.category === selectedCategory)
      }
      return userPosts.filter(
        item =>
          item.title.toLowerCase().indexOf(searchString.toLowerCase()) >= 0 &&
          item.isSold === showSoldItems
      )
    }
    return []
  },
  selectedEvent: state => state.selectedEvent,
  stepperStep: state => state.stepperStep,
  addedNewEventFromDialog: state => state.addedNewEventFromDialog,
  addedDateVenueFromDialog: state => state.addedDateVenueFromDialog,
  ticketSalesId: state => state.ticketSalesId,
  ticketType: state => state.ticketType,
  swapDetails: state => state.swapDetails,
  ticketSeat: state => state.ticketSeat,
  selectedPrice: state => state.selectedPrice,
  allowSwap: state => state.allowSwap,
  sellAsPatch: state => state.sellAsPatch,
  userAddedTickets: state => state.userAddedTickets,
  uploadedTickets: state => state.uploadedTickets
}
