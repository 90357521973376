<template>
  <v-hover v-slot:default="{ hover }">
    <v-card class="mx-auto mb-5 transparent elevation-0" max-width="250">
      <span
        class="d-flex align-center pb-2 text--darken-2"
        :class="{ 'primary--text': !hover, 'subtitle-2': hover }"
      >
        <v-icon
          small
          class="mr-1 text--darken-2"
          :class="{ 'primary--text': !hover }"
        >
          mdi-calendar-range
        </v-icon>
        {{ fullDateMonth(item.startDate) }}
      </span>
      <v-img
        v-ripple
        class="white--text align-end primary-shadow main-border-radius d-block"
        :src="
          item.globalEventId || item.images.length > 0
            ? `https://firebasestorage.googleapis.com/v0/b/piletivahetus-develop.appspot.com/o/events%2Fjpg%2Fg_e_5h4234h${item.globalEventId}h4234h.jpg?alt=media`
            : require('@/assets/img/placeholder_2.png')
        "
        :lazy-src="
          `https://firebasestorage.googleapis.com/v0/b/piletivahetus-develop.appspot.com/o/events%2Fjpg%2Fg_e_5h4234h${item.globalEventId}h4234h.jpg?alt=media`
        "
        style="cursor: pointer;"
        :style="hover ? 'box-shadow: 0px 0px 20px #9B9B9B !important' : ''"
        @click="$router.push(`/uritus/${item.id}`)"
      >
      </v-img>
      <v-card-title
        class="subtitle-1 font-weight-bold pa-0"
        style="min-height: 88px; word-break:break-word; cursor:pointer"
        @click="$router.push(`/uritus/${item.id}`)"
      >
        {{ shortenTheTitle(item.title) }}
      </v-card-title>

      <v-card-subtitle
        class="pb-0 primary--text subtitle-2 text--lighten-1 px-0"
      >
        <v-icon class="primary--text" small>mdi-map-marker</v-icon>
        {{ item.venue.split(',')[0] }}
      </v-card-subtitle>
      <v-card-actions>
        <div class="secondary--text primary--text text-left">
          <span class="subtitle-2 primary--text font-weight-light mr-1"
            >Al.</span
          >
          <span class="display-1 primary--text price font-weight-bold">{{
            item.lowestPrice
          }}</span>
        </div>
      </v-card-actions>
    </v-card>
  </v-hover>
</template>

<script>
import FormatTitle from '@/mixins/FormatTitle'
import FormatDate from '@/mixins/FormatDate'

export default {
  name: 'EventCard',
  mixins: [FormatTitle, FormatDate],
  props: {
    item: {
      type: Object,
      default: () => {},
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.price:after {
  content: '€';
  font-size: 20px;
  position: absolute;
  margin-top: -8px;
}

span,
.v-image {
  transition: 0.3s all ease-out;
}
</style>
