import MessagesDB from '@/firebase/messages-db'
import store from '../store'
import GenericDB from './generic-db'
import firestore from './async-firestore'

// [Optional] Extend GenericDB if you want CRUD operations
export default class ChatsDB extends GenericDB {
  constructor() {
    super(`chats`)
  }

  // Here you can extend PostsDB with custom methods
  async listenForChatChanges() {
    const collectionRef = (await firestore()).collection(this.collectionPath)
    const currentUserId = store.state.authentication.user.id

    const outgoingChatsListener = collectionRef
      .orderBy('createTimestamp', 'desc')
      .where('senderId', '==', currentUserId)
      .onSnapshot(
        documents => {
          documents.docChanges().forEach(change => {
            const mappedChat = this.convertObjectTimestampPropertiesToDate({
              id: change.doc.id,
              ...change.doc.data()
            })
            if (change.type === 'added') {
              store.commit('chats/addChat', mappedChat)
              if (store.state.chats.chats.length > 0) {
                store.state.chats.chats.forEach(element => {
                  return new MessagesDB(element.id)
                    .getUnseenMessagesCount(element.id)
                    .then(() => {
                      store.commit('app/setError', null, { root: true })
                      store.commit(
                        'app/setLoading',
                        { type: 'getUnseenMessagesCount', state: false },
                        { root: true }
                      )
                    })
                    .catch(error => {
                      store.commit('app/setError', error, { root: true })
                      store.commit(
                        'app/setLoading',
                        { type: 'getUnseenMessagesCount', state: false },
                        { root: true }
                      )
                    })
                })
              }
            } else if (change.type === 'modified') {
              store.commit('chats/updateChat', mappedChat)
            } else if (change.type === 'removed') {
              store.commit('chats/removeChatById', mappedChat.id)
            }
          })
        }
        // err => {
        //   console.log(`Encountered error: ${err}`)
        // }
      )
    store.commit('chats/setObserver', outgoingChatsListener)
    const incomingChatsListener = collectionRef
      .orderBy('createTimestamp', 'desc')
      .where('receiverId', '==', currentUserId)
      .onSnapshot(
        documents => {
          documents.docChanges().forEach(change => {
            const mappedChat = this.convertObjectTimestampPropertiesToDate({
              id: change.doc.id,
              ...change.doc.data()
            })
            if (change.type === 'added') {
              store.commit('chats/addChat', mappedChat)
              if (store.state.chats.chats.length > 0) {
                store.state.chats.chats.forEach(element => {
                  return new MessagesDB(element.id)
                    .getUnseenMessagesCount(element.id)
                    .then(() => {
                      store.commit('app/setError', null, { root: true })
                      store.commit(
                        'app/setLoading',
                        { type: 'chatObserver', state: false },
                        { root: true }
                      )
                    })
                    .catch(error => {
                      store.commit('app/setError', error, { root: true })
                      store.commit(
                        'app/setLoading',
                        { type: 'chatObserver', state: false },
                        { root: true }
                      )
                    })
                })
              }
            } else if (change.type === 'modified') {
              store.commit('chats/updateChat', mappedChat)
            }
          })
        }
        // err => {
        //   console.log(`Encountered error: ${err}`)
        // }
      )
    store.commit('chats/setObserver', incomingChatsListener)
  }
}
