import router from '@/router/index'
import ChatsDB from '@/firebase/chats-db'
import MessagesDB from '@/firebase/messages-db'
import UsersDB from '@/firebase/users-db'
import TokensDB from '@/firebase/tokens-db'

export default {
  /**
   * Make item sellable in chat so we know that buyer can send money now
   */
  setItemStatus: async ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      commit('app/setError', null, { root: true })
      commit(
        'app/setLoading',
        { type: 'setItemStatus', state: true },
        { root: true }
      )
      try {
        new ChatsDB(payload.chatId).update({
          id: payload.chatId,
          isSellable: payload.isSellable
        })
        commit('app/setError', null, { root: true })
        commit(
          'app/setLoading',
          { type: 'setItemStatus', state: false },
          { root: true }
        )
        resolve(true)
      } catch (error) {
        commit('app/setError', error, { root: true })
        commit(
          'app/setLoading',
          { type: 'setItemStatus', state: false },
          { root: true }
        )
        reject(error)
      }
    })
  },
  /**
   * Get user contacts (all users that the current user has a chat with)
   */
  getUserContacts: async ({ commit, state }) => {
    return new Promise((resolve, reject) => {
      commit('app/setError', null, { root: true })
      commit(
        'app/setLoading',
        { type: 'getUserContacts', state: true },
        { root: true }
      )
      try {
        /**
         * Find user from chats array and get info
         */
        state.chats.forEach(async chat => {
          const allUserChatParticipants = await new UsersDB().read(
            chat.senderId
          )
          commit('setAllUserChatParticipants', allUserChatParticipants)
        })
        commit('app/setError', null, { root: true })
        commit(
          'app/setLoading',
          { type: 'getUserContacts', state: false },
          { root: true }
        )
        resolve(true)
      } catch (error) {
        commit('app/setError', error, { root: true })
        commit(
          'app/setLoading',
          { type: 'getUserContacts', state: false },
          { root: true }
        )
        reject(error)
      }
    })
  },
  /**
   * Create chat room between two users.
   * @param {Object} item Has to contain id, userId, title and images
   */
  createRoom: async ({ commit, dispatch, rootState }, payload) => {
    return new Promise((resolve, reject) => {
      commit('app/setError', null, { root: true })
      commit(
        'app/setLoading',
        { type: 'createRoom', state: false },
        { root: true }
      )
      try {
        const senderId = rootState.authentication.user.id
        const receiverId = payload.item.ticket.sellerId
        const roomId = `${payload.item.ticket.id}_${senderId}`
        const chatAlreadyCreated = rootState.chats.chats.some(elem => {
          return elem.id === roomId
        })
        if (!chatAlreadyCreated) {
          new ChatsDB().create(
            {
              senderFirstName: rootState.authentication.user.firstName,
              senderLastName: rootState.authentication.user.lastName,
              senderId,
              receiverId,
              event: payload.item.event,
              ticket: payload.item.ticket,
              isSold: false
            },
            roomId
          )
          dispatch('sendMessage', {
            roomId,
            type: 'system',
            author: 'support',
            senderName: rootState.authentication.user.firstName,
            data: {
              text: `Tundub, et ${rootState.authentication.user.firstName} on Sinu "${payload.item.event.title}" piletist huvitatud. Te saate vestlust jätkata siit...`
            },
            seenBy: ''
          }).then(() => {
            resolve()
            // dispatch('sendNotification', {
            //   to: receiverId,
            //   notification: {
            //     title: 'You got new message!',
            //     selectedProfileItem: 'Messages',
            //     body: 'Somebody is interested in your listing',
            //     icon: payload.item.images[0]
            //   }
            // })
            commit(
              'app/setSnackbar',
              {
                show: true,
                type: 'success',
                message: 'Vestluse loomine oli edukas!',
                actionMessage: 'Ava sõnumid',
                action: () => {
                  commit(
                    'app/setSelectedProfileMenuItem',
                    payload.this.$vuetify.lang.t(
                      '$vuetify.profileMenuItems.messages'
                    ),
                    {
                      root: true
                    }
                  )
                  router.push('/profiil', () => {})
                }
              },
              { root: true }
            )
          })
        } else {
          commit(
            'app/setSnackbar',
            {
              show: true,
              type: 'error',
              message: 'Vestlus juba loodud!'
            },
            { root: true }
          )
        }
      } catch (error) {
        commit('app/setError', error, { root: true })
        reject(error)
        commit(
          'app/setLoading',
          { type: 'createRoom', state: false },
          { root: true }
        )
      }
    })
  },
  sendNotification: async ({ commit }, payload) => {
    commit('app/setError', null, { root: true })
    commit(
      'app/setLoading',
      { type: 'sendNotification', state: true },
      { root: true }
    )
    try {
      const sendTo = await new TokensDB().read(payload.to)

      const serverKey = process.env.VUE_APP_FCM_SERVER_KEY
      if (sendTo && sendTo !== null) {
        fetch(`https://fcm.googleapis.com/fcm/send`, {
          method: 'POST',
          body: JSON.stringify({
            to: sendTo.token,
            priority: 'high',
            notification: payload.notification
          }),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `key=${serverKey}`
          }
        }).then(() => {
          commit('app/setError', null, { root: true })
          commit(
            'app/setLoading',
            { type: 'sendNotification', state: false },
            { root: true }
          )
        })
      } else {
        commit('app/setError', null, { root: true })
        commit(
          'app/setLoading',
          { type: 'sendNotification', state: false },
          { root: true }
        )
      }
    } catch (error) {
      commit('app/setError', error, { root: true })
      commit(
        'app/setLoading',
        { type: 'sendNotification', state: false },
        { root: true }
      )
    }
  },
  getChatMessages: async ({ commit, getters }) => {
    commit('app/setError', null, { root: true })
    commit(
      'app/setLoading',
      { type: 'getChatMessages', state: true },
      { root: true }
    )
    try {
      new MessagesDB(getters.selectedChat.id).readAllSnapshots().then(() => {
        commit('app/setError', null, { root: true })
        commit(
          'app/setLoading',
          { type: 'getChatMessages', state: false },
          { root: true }
        )
      })
    } catch (error) {
      commit('app/setError', error, { root: true })
      commit(
        'app/setLoading',
        { type: 'getChatMessages', state: false },
        { root: true }
      )
    }
  },
  getMoreChatMessages: async ({ commit }, chatId) => {
    commit('app/setError', null, { root: true })
    commit(
      'app/setLoading',
      { type: 'getChatMessages', state: true },
      { root: true }
    )
    try {
      new MessagesDB(chatId).getMoreMessages().then(() => {
        commit('app/setError', null, { root: true })
        commit(
          'app/setLoading',
          { type: 'getChatMessages', state: false },
          { root: true }
        )
      })
    } catch (error) {
      commit('app/setError', error, { root: true })
      commit(
        'app/setLoading',
        { type: 'getChatMessages', state: false },
        { root: true }
      )
    }
  },
  sendMessage: async ({ commit, dispatch, state, rootState }, message) => {
    commit('app/setError', null, { root: true })
    commit(
      'app/setLoading',
      { type: 'sendMessage', state: true },
      { root: true }
    )
    try {
      const roomId = message.roomId ? message.roomId : state.selectedChat.id
      const type = message.data ? message.type : state.selectedChat.type
      const author =
        message.author === 'support'
          ? 'support'
          : rootState.authentication.user.id
      const newMessage = {
        roomId,
        type,
        author,
        senderName: rootState.authentication.user.firstName,
        data: {
          text: message.data.emoji ? message.data.emoji : message.data.text,
          meta: '✓ Kohal'
        },
        seenBy: ''
      }
      const sentMessage = await new MessagesDB(roomId).create(newMessage)
      commit('addMessage', sentMessage)
      if (author !== 'support') {
        const sendNotificationTo = state.participants.find(to => {
          return author !== to.id
        })
        dispatch('sendNotification', {
          to: sendNotificationTo.id,
          notification: {
            title: 'Sul on uusi sõnumeid!',
            selectedProfileItem: 'Messages',
            body: message.data.emoji ? message.data.emoji : message.data.text,
            icon: rootState.authentication.user.photoUrl
          }
        })
      }
      commit(
        'app/setLoading',
        { type: 'sendMessage', state: false },
        { root: true }
      )
    } catch (error) {
      commit('app/setError', error, { root: true })
      commit(
        'app/setLoading',
        { type: 'sendMessage', state: false },
        { root: true }
      )
    }
  },
  getAllChats: async ({ commit, rootState }) => {
    commit('app/setError', null, { root: true })
    commit(
      'app/setLoading',
      { type: 'getAllChats', state: true },
      { root: true }
    )
    try {
      const currentUser = rootState.authentication.user
      // const outgoingChats = await new ChatsDB().readAll([
      //   ['senderId', '==', currentUserId]
      // ])
      // const incomingChats = await new ChatsDB().readAll([
      //   ['receiverId', '==', currentUserId]
      // ])
      // commit('setChats', [...outgoingChats, ...incomingChats])
      if (currentUser) {
        await new ChatsDB().listenForChatChanges()
        commit(
          'app/setLoading',
          { type: 'getAllChats', state: false },
          { root: true }
        )
      }
    } catch (error) {
      commit('app/setError', error, { root: true })
      commit(
        'app/setLoading',
        { type: 'getAllChats', state: false },
        { root: true }
      )
    }
  },
  getParticipantsInfo: async ({ commit, getters }) => {
    commit(
      'app/setLoading',
      { type: 'getParticipantsInfo', state: true },
      { root: true }
    )
    commit('app/setError', null, { root: true })
    try {
      const sender = await new UsersDB().read(getters.selectedChat.senderId)
      commit('setParticipants', sender)
      const receiver = await new UsersDB().read(getters.selectedChat.receiverId)
      commit('setParticipants', receiver)

      commit(
        'app/setLoading',
        { type: 'getParticipantsInfo', state: false },
        { root: true }
      )
      commit('app/setError', null, { root: true })
    } catch (error) {
      commit(
        'app/setLoading',
        { type: 'getParticipantsInfo', state: false },
        { root: true }
      )
      commit('app/setError', error, { root: true })
    }
  }
}
