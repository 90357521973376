import { isNil } from 'lodash'
import { firebase } from '@firebase/app'
import { desktop as isDesktop } from 'is_js'
import router from '@/router'
import UsersDB from '@/firebase/users-db'
import StripeCustomersDB from '@/firebase/stripe-customers-db'
// import MessagesDB from '@/firebase/messages-db'
// import ChatsDB from '@/firebase/chats-db'

export default {
  getUserRating: ({ commit }, userId) => {
    return new Promise((resolve, reject) => {
      commit('app/setError', null, { root: true })
      commit(
        'app/setLoading',
        { type: 'getUserRating', state: true },
        { root: true }
      )
      try {
        let total = 0.0
        let average = 0.0
        new UsersDB().getUserRating(userId).then(response => {
          response.forEach(element => {
            total += element.rating
          })
          average = total / response.length
          resolve(average)
        })
      } catch (error) {
        commit('app/setError', error, { root: true })
        commit(
          'app/setLoading',
          { type: 'getUserRating', state: false },
          { root: true }
        )
        reject(error)
      }
    })
  },
  rateUser: ({ commit }, data) => {
    commit('app/setError', null, { root: true })
    return new Promise((resolve, reject) => {
      commit(
        'app/setLoading',
        { type: 'rateUser', state: true },
        { root: true }
      )
      try {
        // TODO:: For some reason the userId has space at the end so we gotta remove spaces
        new UsersDB()
          .rateUser(data.userId.replace(/\s/g, ''), data.rating)
          .then(user => {
            commit('app/setError', null, { root: true })
            commit(
              'app/setLoading',
              { type: 'rateUser', state: false },
              { root: true }
            )
            resolve(user)
          })
      } catch (error) {
        commit('app/setError', error, { root: true })
        commit(
          'app/setLoading',
          { type: 'rateUser', state: false },
          { root: true }
        )
        reject(error)
      }
    })
  },
  getUser: ({ commit }, userId) => {
    return new Promise((resolve, reject) => {
      commit('app/setLoading', { type: 'getUser', state: true }, { root: true })
      commit('app/setError', null, { root: true })
      try {
        // TODO:: For some reason the userId has space at the end so we gotta remove spaces
        new UsersDB().read(userId.replace(/\s/g, '')).then(user => {
          commit('app/setError', null, { root: true })
          commit(
            'app/setLoading',
            { type: 'getUser', state: false },
            { root: true }
          )
          resolve(user)
        })
      } catch (error) {
        commit('app/setError', error, { root: true })
        commit(
          'app/setLoading',
          { type: 'getUser', state: false },
          { root: true }
        )
        reject(error)
      }
    })
  },
  getStripeUserAccount: ({ commit }, userId) => {
    return new Promise((resolve, reject) => {
      commit('app/setError', null, { root: true })
      commit(
        'app/setLoading',
        { type: 'getStripeUserAccount', state: true },
        { root: true }
      )
      try {
        const user = new StripeCustomersDB().read(userId)
        commit('app/setError', null, { root: true })
        commit(
          'app/setLoading',
          { type: 'getStripeUserAccount', state: false },
          { root: true }
        )
        resolve(user)
      } catch (error) {
        commit('app/setError', error, { root: true })
        commit(
          'app/setLoading',
          { type: 'getStripeUserAccount', state: false },
          { root: true }
        )
        reject(error)
      }
    })
  },
  updateCurrentUserWithData: async ({ commit, rootState }, payload) => {
    commit('app/setError', null, { root: true })
    commit(
      'app/setLoading',
      { type: 'updateCurrentUserWithData', state: true },
      { root: true }
    )
    try {
      new UsersDB().update({
        id: rootState.authentication.user.id,
        ...payload.data
      })
      commit('app/setError', null, { root: true })
      commit(
        'app/setLoading',
        { type: 'updateCurrentUserWithData', state: false },
        { root: true }
      )
      commit(
        'app/setSnackbar',
        {
          show: true,
          type: 'success',
          message: `${payload.updatedProperty} ${payload.this.$vuetify.lang.t(
            '$vuetify.general.updatedSuccessfully'
          )}`
        },
        { root: true }
      )
    } catch (error) {
      commit('app/setError', error, { root: true })
      commit(
        'app/setLoading',
        { type: 'updateCurrentUserWithData', state: false },
        { root: true }
      )
      if (process.env.NODE_END !== 'production') {
        commit(
          'app/setSnackbar',
          {
            show: true,
            type: 'error',
            message:
              process.env.NODE_END !== 'production'
                ? `Error updating user with phone number: ${error.message}`
                : 'Something went wrong! Please try again later.'
          },
          { root: true }
        )
      }
    }
  },
  verifyCurrentUserEmail: async ({ commit, rootState }) => {
    if (firebase.auth().currentUser) {
      new UsersDB()
        .update({
          id: rootState.authentication.user.id,
          emailVerified: true
        })
        .then(() => {
          commit('verifyCurrentUserEmail')
        })
    }
  },
  autoLogin: async ({ commit, dispatch, rootState }, firebaseUser) => {
    commit('app/setError', null, { root: true })
    commit('app/setLoading', { type: 'autoLogin', state: true }, { root: true })
    try {
      const currentUser = await new UsersDB().read(firebaseUser.uid)
      if (!isNil(currentUser)) {
        //   const newUser = {
        //     id: firebaseUser.uid,
        //     firstName: currentUser.firstName,
        //     lastName: currentUser.lastName,
        //     email: firebaseUser.email,
        //     photoUrl: firebaseUser.photoURL,
        //     emailVerified: firebaseUser.emailVerified
        //   }
        commit('app/setError', null, { root: true })
        commit('setUser', currentUser)
        commit(
          'app/setLoading',
          { type: 'autoLogin', state: false },
          { root: true }
        )
        if (rootState.authentication.user) {
          // await dispatch('posts/getAllPinnedPosts', null, { root: true })
          await dispatch('chats/getAllChats', false, { root: true })
        }
        // if (rootState.chats.chats.length > 0) {
        //   rootState.chats.chats.forEach(element => {
        //     return new MessagesDB(element.id)
        //       .getUnseenMessagesCount(element.id)
        //       .then(() => {
        //         commit('app/setError', null, { root: true })
        //         commit(
        //           'app/setLoading',
        //           { type: 'autoLogin', state: false },
        //           { root: true }
        //         )
        //       })
        //       .catch(error => {
        //         commit('app/setError', error, { root: true })
        //         commit(
        //           'app/setLoading',
        //           { type: 'autoLogin', state: false },
        //           { root: true }
        //         )
        //       })
        //   })
        // }
      } else {
        commit('setUser', undefined)
        commit(
          'app/setLoading',
          { type: 'autoLogin', state: false },
          { root: true }
        )
      }
    } catch (error) {
      commit('setUser', undefined)
      commit('app/setError', null, { root: true })
      commit(
        'app/setLoading',
        { type: 'autoLogin', state: true },
        { root: true }
      )
      if (process.env.NODE_END !== 'production') {
        commit(
          'app/setSnackbar',
          {
            show: true,
            type: 'error',
            message: `Autologin failed!: ${error.message}`
          },
          { root: true }
        )
      }
    }
  },
  /**
   * Callback fired when user login
   */
  login: async ({ commit, dispatch }, data) => {
    commit('app/setError', null, { root: true })
    commit('app/setLoading', { type: 'login', state: true }, { root: true })
    // LOCAL = ALWAYS LOGGED IN TRUE
    // SESSION = IF TAB IS CLOSED, LOGGED OUT FALSE
    const persistenceMode = data.rememberMe
      ? firebase.auth.Auth.Persistence.LOCAL
      : firebase.auth.Auth.Persistence.SESSION

    firebase.auth().setPersistence(persistenceMode)

    try {
      const firebaseUser = await firebase
        .auth()
        .signInWithEmailAndPassword(data.email, data.password)

      if (firebaseUser) {
        const createdUser = await new UsersDB().read(firebaseUser.user.uid)
        commit('setUser', createdUser)
        commit('app/setError', null, { root: true })
        commit(
          'app/setLoading',
          { type: 'login', state: false },
          { root: true }
        )
        // dispatch('posts/getAllPinnedPosts', null, { root: true })
        dispatch('chats/getAllChats', false, { root: true })

        // if (rootState.chats.chats.length > 0) {
        //   rootState.chats.chats.forEach(element => {
        //     return new MessagesDB(element.id)
        //       .getUnseenMessagesCount(element.id)
        //       .then(() => {
        //         commit('app/setError', null, { root: true })
        //         commit(
        //           'app/setLoading',
        //           { type: 'login', state: false },
        //           { root: true }
        //         )
        //       })
        //       .catch(error => {
        //         commit('app/setError', error, { root: true })
        //         commit(
        //           'app/setLoading',
        //           { type: 'login', state: false },
        //           { root: true }
        //         )
        //       })
        //   })
        // }
      }
    } catch (error) {
      commit('app/setError', error, { root: true })
      commit('setUser', undefined)
      commit('app/setLoading', { type: 'login', state: false }, { root: true })
      // commit(
      //   'app/setSnackbar',
      //   {
      //     show: true,
      //     type: 'error',
      //     message:
      //       process.env.NODE_END !== 'production'
      //         ? `Tekkis viga sisselogimisega: ${error.message}`
      //         : 'Miskit läks nihu. Proovi uuest!'
      //   },
      //   { root: true }
      // )
    }
  },
  signInWithGoogle: async ({ commit, dispatch }) => {
    commit('app/setError', null, { root: true })
    commit(
      'app/setLoading',
      { type: 'signInWithGoogle', state: false },
      { root: true }
    )
    const provider = new firebase.auth.GoogleAuthProvider()
    commit('setUser', undefined)
    try {
      // Firebase signin with popup is faster than redirect
      // but we can't use it on mobile because it's not well supported
      // when app is running as standalone on ios & android
      // eslint-disable-next-line no-unused-expressions
      const newUser = isDesktop()
        ? await firebase.auth().signInWithPopup(provider)
        : await firebase.auth().signInWithRedirect(provider)
      if (
        newUser.additionalUserInfo.isNewUser &&
        newUser.additionalUserInfo.profile.verified_email
      ) {
        await firebase.auth().currentUser.sendEmailVerification()
        dispatch('getUser', newUser.user.uid).then(user => {
          commit('setUser', user)
        })
      } else {
        dispatch('getUser', newUser.user.uid).then(user => {
          commit('setUser', user)
        })
      }
      commit('app/setError', null, { root: true })
      commit(
        'app/setLoading',
        { type: 'signInWithGoogle', state: false },
        { root: true }
      )
    } catch (error) {
      commit('app/setError', error, { root: true })
      commit(
        'app/setLoading',
        { type: 'signInWithGoogle', state: false },
        { root: true }
      )
      commit('setUser', null)
    }
  },
  signInWithFacebook: async ({ commit }) => {
    commit('app/setError', null, { root: true })
    commit(
      'app/setLoading',
      { type: 'signInWithFacebook', state: false },
      { root: true }
    )
    const provider = new firebase.auth.FacebookAuthProvider()
    commit('setUser', undefined)
    try {
      // Firebase signin with popup is faster than redirect
      // but we can't use it on mobile because it's not well supported
      // when app is running as standalone on ios & android
      // eslint-disable-next-line no-unused-expressions
      isDesktop()
        ? await firebase.auth().signInWithPopup(provider)
        : await firebase.auth().signInWithRedirect(provider)
      commit('app/setError', null, { root: true })
      commit(
        'app/setLoading',
        { type: 'signInWithFacebook', state: false },
        { root: true }
      )
    } catch (error) {
      commit('app/setError', error, { root: true })
      commit(
        'app/setLoading',
        { type: 'signInWithFacebook', state: false },
        { root: true }
      )
      commit('setUser', null)
    }
  },
  register: ({ commit, dispatch }, data) => {
    commit('app/setError', null, { root: true })
    commit('app/setLoading', { type: 'register', state: false }, { root: true })
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .createUserWithEmailAndPassword(data.email, data.password)
        .then(createdUser => {
          firebase
            .auth()
            .currentUser.updateProfile({
              displayName: [data.firstName, data.lastName].join(' '),
              photoURL: `https://www.gravatar.com/avatar/${data.email
                .trim()
                .toLowerCase()}?s=200&r=g&d=retro`
            })
            .then(() => {
              new UsersDB()
                .read(createdUser.user.uid)
                .then(currentUserFromDatabase => {
                  if (!isNil(createdUser) && isNil(currentUserFromDatabase)) {
                    const newUser = {
                      id: createdUser.user.uid,
                      email: createdUser.user.email,
                      firstName: data.firstName,
                      lastName: data.lastName,
                      photoUrl: createdUser.user.photoURL,
                      emailVerified: createdUser.user.emailVerified,
                      name: `${data.firstName} ${data.lastName}`,
                      ip: data.ip ? data.ip : ''
                    }
                    new UsersDB()
                      .create(newUser, createdUser.user.uid)
                      .then(() => {
                        commit('setUser', newUser)
                        firebase.auth().currentUser.sendEmailVerification()
                        commit('app/setError', null, { root: true })
                        commit(
                          'app/setLoading',
                          { type: 'register', state: false },
                          { root: true }
                        )
                        // dispatch('posts/getAllPinnedPosts', null, {
                        //   root: true
                        // })

                        // if (rootState.chats.chats.length > 0) {
                        //   rootState.chats.chats.forEach(element => {
                        //     return new MessagesDB(
                        //       element.id
                        //     ).getUnseenMessagesCount(element.id)
                        //   })
                        // }
                      })
                  }
                })
            })
          dispatch('chats/getAllChats', false, { root: true })
          resolve()
        })
        .catch(error => {
          commit('app/setError', error, { root: true })
          commit('setUser', null)
          commit(
            'app/setLoading',
            { type: 'register', state: false },
            { root: true }
          )
          commit(
            'app/setSnackbar',
            {
              show: true,
              type: 'error',
              message: error.message
            },
            { root: true }
          )
          reject()
        })
    })
  },

  /**
   * Callback fired when user logs out
   */
  logout: ({ commit, rootState }) => {
    if (rootState.events.events.length > 0) {
      commit('events/clearEvents', null, { root: true })
    }
    if (rootState.events.userTickets.length > 0) {
      commit('events/clearUserTickets', null, { root: true })
    }
    if (rootState.chats.chats.length > 0) {
      commit('chats/clearChats', null, { root: true })
    }
    if (rootState.chats.observers.length > 0) {
      rootState.chats.observers.forEach(observer => {
        if (observer.listener) {
          observer.listener()
        } else {
          observer()
        }
      })
    }
    // if (rootState.orders.observers.length > 0) {
    //   rootState.orders.observers.forEach(observer => {
    //     if (observer.listener) {
    //       observer.listener()
    //     } else {
    //       observer()
    //     }
    //   })
    // }
    commit('setUser', null)
    const currentRouter = router.app.$route
    if (!(currentRouter.meta && currentRouter.meta.authNotRequired)) {
      router.replace('/logi-sisse')
    }
  }
}
