import store from '../store'
import GenericDB from './generic-db'
import firestore from './async-firestore'
// [Optional] Extend GenericDB if you want CRUD operations
let start = null
let end = null
export default class MessagesDB extends GenericDB {
  constructor(chatId) {
    super(`chats/${chatId}/messages`)
  }

  // Here you can extend PostsDB with custom methods
  async readAllSnapshots() {
    const collectionRef = (await firestore()).collection(this.collectionPath)
    collectionRef
      .orderBy('createTimestamp', 'desc')
      .limit(10)
      .get()
      .then(snapshot => {
        start = this.getLastDoc(snapshot)
        if (start) {
          const mappedStart = this.convertObjectTimestampPropertiesToDate({
            id: start.id,
            ...start.data()
          })
          store.commit('chats/setStart', mappedStart)
          const listener = collectionRef
            .orderBy('createTimestamp')
            .startAt(start)
            .onSnapshot(messages => {
              messages.docChanges().forEach(change => {
                const message = this.convertObjectTimestampPropertiesToDate({
                  id: change.doc.id,
                  ...change.doc.data()
                })
                if (
                  change.type === 'added' &&
                  message.createTimestamp !== null
                ) {
                  store.commit('chats/addMessage', message)
                }
                if (change.type === 'modified' && message.seenBy !== '') {
                  store.commit('chats/modifyMessage', message)
                }
                if (
                  change.type === 'added' &&
                  message.seenBy === '' &&
                  message.author !== 'support' &&
                  message.author !== store.getters['authentication/user'].id
                ) {
                  this.update({
                    id: change.doc.id,
                    seenBy: store.getters['authentication/user'].id,
                    data: {
                      text: message.data.text,
                      meta: '✓ Nähtud'
                    }
                  }).then(() => {
                    this.getUnseenMessagesCount(this.chatId)
                  })
                }
              })
            })
          store.commit('chats/setObserver', {
            listener,
            from: 'readAllSnapshots'
          })
        }
      })
      .catch(error => {
        store.commit('app/setError', error)
      })
  }

  async getMoreMessages() {
    const collectionRef = (await firestore()).collection(this.collectionPath)
    if (start) {
      collectionRef
        .orderBy('createTimestamp', 'desc')
        .startAt(start)
        .limit(10)
        .get()
        .then(snapshot => {
          end = start
          start = snapshot.docs[snapshot.docs.length - 1]
          // const mappedStart = this.convertObjectTimestampPropertiesToDate({
          //   id: start.id,
          //   ...start.data()
          // })
          // const mappedEnd = this.convertObjectTimestampPropertiesToDate({
          //   id: end.id,
          //   ...end.data()
          // })
          const listener = collectionRef
            .orderBy('createTimestamp')
            .startAt(start)
            .endBefore(end)
            .onSnapshot(messages => {
              messages.forEach(message => {
                const mappedMessage = this.convertObjectTimestampPropertiesToDate(
                  {
                    id: message.id,
                    ...message.data()
                  }
                )
                store.commit('chats/addMessage', mappedMessage)
                if (mappedMessage.seenBy === '') {
                  this.update({
                    id: mappedMessage.id,
                    seenBy: store.getters['authentication/user'].id,
                    data: {
                      text: mappedMessage.data.text,
                      meta: '✓ Nähtud'
                    }
                  })
                }
              })
            })
          store.commit('chats/setObserver', {
            listener,
            from: 'getMoreMessages'
          })
        })
    }
  }

  // helper to get the last document in a QuerySnapShot
  getLastDoc = snap => {
    if (snap.empty) return null
    return snap.docs[snap.docs.length - 1]
  }

  // helper to get unread messages count
  async getUnseenMessagesCount(chatId) {
    const collectionRef = (await firestore()).collection(this.collectionPath)
    const listener = collectionRef
      .where('seenBy', '==', '')
      .orderBy('author')
      .where('author', '<', 'support')
      // .where('author', '<', store.getters['authentication/user'].id)
      .onSnapshot(snapshot => {
        if (snapshot.docs.length > 0) {
          snapshot.forEach(element => {
            const mappedMessage = this.convertObjectTimestampPropertiesToDate({
              id: element.id,
              ...element.data()
            })
            if (
              mappedMessage.author !== store.getters['authentication/user'].id
            ) {
              const message = this.convertObjectTimestampPropertiesToDate({
                id: chatId,
                count: snapshot.docs.length
              })
              store.commit('chats/setUnreadMessagesCount', message)
            }
          })
        } else {
          const message = this.convertObjectTimestampPropertiesToDate({
            id: chatId,
            count: 0
          })
          store.commit('chats/setUnreadMessagesCount', message)
        }
      })
    store.commit('chats/setObserver', {
      listener,
      from: 'getUnseenMessagesCount'
    })
  }
}
