import Vue from 'vue'

export default {
  setChats: (state, chats) => {
    state.chats = chats
  },
  addChat: (state, chat) => {
    const exists = state.chats.find(c => {
      return c.id === chat.id
    })
    if (!exists) {
      state.chats.push(chat)
    }
  },
  removeChatById: (state, chatId) => {
    const index = state.chats.findIndex(chat => chat.id === chatId)
    state.chats.splice(index, 1)
  },
  updateChat: (state, chat) => {
    const existingChat = state.chats.find(c => c.id === chat.id)
    Object.assign(existingChat, chat)
  },
  setParticipants: (state, participant) => {
    if (state.participants.length === 2) {
      state.participants.length = 0
    }
    state.participants.push(participant)
  },
  setSelectedChat: (state, value) => {
    state.selectedChat = value
  },
  clearMessages: state => {
    state.messages.length = 0
  },
  clearChats: state => {
    state.chats.length = 0
  },
  addMessage: (state, message) => {
    const exists = state.messages.find(m => {
      return m.id === message.id
    })
    if (!exists) {
      state.messages.push(message)
    }
  },
  setMessages: (state, messages) => {
    state.messages = messages
  },
  setObserver: (state, observer) => {
    state.observers.push(observer)
  },
  setStart: (state, doc) => {
    state.start = doc
  },
  setEnd: (state, value) => {
    state.end = value
  },
  setUnreadMessagesCount: (state, value) => {
    const existingIds = state.unreadMessagesCount.map(obj => obj.id)
    if (!existingIds.includes(value.id)) {
      state.unreadMessagesCount.push(value)
    } else {
      const chat = state.unreadMessagesCount.find(
        chatInUnreadMessagesCount => chatInUnreadMessagesCount.id === value.id
      )
      Vue.set(chat, 'count', value.count)
    }
  },
  setAllUserChatParticipants: (state, value) => {
    const existingIds = state.allUserChatParticipants.map(obj => obj.id)
    if (!existingIds.includes(value.id)) {
      state.allUserChatParticipants.push(value)
    }
  },
  modifyMessage: (state, value) => {
    const m = state.messages.find(message => {
      return message.id === value.id
    })
    m.data.meta = '✓ Nähtud'
  }
}
