<template>
  <v-container
    id="header-wrapper"
    :class="{ 'header-wrapper': full === false }"
    class="d-flex align-start pl-md-10 flex-column pl-4"
    fluid
  >
    <v-row class="mx-auto" style="width:100%">
      <v-col class="pb-0 pr-6 pl-1 pl-md-0 pr-md-0" cols="7">
        <router-link :to="'/'">
          <v-img
            :src="require('../assets/img/Piletivahetuse_logo_valge.svg')"
            :lazy-src="require('../assets/img/Piletivahetuse_logo_valge.svg')"
            max-width="244"
          ></v-img
        ></router-link>
      </v-col>
      <v-col
        class="pb-0 pl-0"
        cols="5"
        :style="$vuetify.breakpoint.smAndDown ? 'padding-right:0;' : ''"
      >
        <v-btn
          v-if="!isAuthenticated"
          text
          class="float-right white--text text-capitalize"
          to="/logi-sisse"
        >
          <v-icon class="pr-2">mdi-account</v-icon>
          Logi sisse
        </v-btn>
        <v-btn
          v-if="isAuthenticated"
          text
          class="float-right white--text text-capitalize"
          :to="'/profiil'"
        >
          <v-icon class="pr-2">mdi-account</v-icon>
          Minu konto
        </v-btn>
      </v-col>
    </v-row>
    <v-row
      v-if="$route.path !== '/lisa-pilet'"
      class="mx-auto flex-wrap"
      style="width:100%"
    >
      <v-col class="text-left text-sm-right text-col pt-5 pb-0 mb-5">
        <div
          class="display-1 white--text font-italic font-weight-light text-left text-sm-right"
          :style="
            $vuetify.breakpoint.smAndDown ? 'font-size:18px !important' : ''
          "
        >
          Piletivahetus on imelihtne
        </div>
        <v-btn
          text
          dark
          class="white--text mt-3 text-capitalize"
          :style="
            $vuetify.breakpoint.smAndUp
              ? 'padding:14px 112px;'
              : 'padding:14px 84px;'
          "
          style="background: #003650; border-radius: 10px; height:auto"
          @click="$router.replace('/lisa-pilet', () => {})"
        >
          <v-icon left size="28" style="position:absolute; left:-65px"
            >mdi-plus</v-icon
          >
          Lisa pilet
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="mx-auto" style="width:100%">
      <v-col class="d-flex align-end col-10">
        <div
          class="headline white--text header-title"
          style="font-weight:500"
          :style="
            $vuetify.breakpoint.smAndDown ? 'font-size:18px !important' : ''
          "
        >
          <div
            v-if="
              !$route.matched.some(({ name }) => name === 'event') &&
                $route.path !== '/lisa-pilet'
            "
          >
            {{ title() }}
          </div>
          <v-btn
            v-if="$route.matched.some(({ name }) => name === 'event')"
            text
            color="white"
            @click="$router.go(-1)"
          >
            <v-icon>mdi-chevron-left</v-icon>
            {{ $vuetify.lang.t('$vuetify.general.back') }}
          </v-btn>
        </div>
      </v-col>
      <v-col class="d-flex justify-end text-right col-2 px-0 px-sm-3">
        <a
          href="https://www.facebook.com/piletivahetus"
          target="_blank"
          style="color:rgba(0,0,0,0)"
        >
          <v-hover v-slot:default="{ hover }">
            <v-icon :color="hover ? 'blue' : ''" class="mr-2" @click="false"
              >mdi-facebook</v-icon
            >
          </v-hover>
        </a>
        <a
          href="https://www.instagram.com/piletivahetus"
          target="_blank"
          style="color:rgba(0,0,0,0)"
        >
          <v-hover v-slot:default="{ hover }">
            <v-icon :color="hover ? 'purple' : ''" @click="false"
              >mdi-instagram</v-icon
            >
          </v-hover>
        </a>
      </v-col>
      <!-- <v-col class="d-flex justify-end align-end flex-nowrap">
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-btn
              class="white--text bg-primary darken mt-3"
              style="background: #003650; border-radius: 10px; padding:14px 112px; height:auto"
              v-on="on"
              >text</v-btn
            >
          </template>
          <v-list>
            <v-list-item @click="sort()">
              <v-list-item-title>
                Järjesta kuupäeva järgi
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="sort()">
              <v-list-item-title>
                Järjesta hinna järgi
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-text-field
          id="id"
          name="search"
          label="Otsi sündmust"
          background-color="#ffffff"
          style="border-radius:10px;"
          height="45px"
        ></v-text-field>
      </v-col> -->
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Header',
  props: {
    full: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    ...mapGetters('authentication', ['isAuthenticated'])
  },
  methods: {
    title() {
      if (this.$route.matched.some(({ name }) => name === 'landing')) {
        return 'Saadaval olevad piletid'
      }
      if (this.$route.matched.some(({ name }) => name === 'uritus')) {
        return 'Müügilolevad piletid'
      }
      if (this.$route.matched.some(({ name }) => name === 'add-ticket')) {
        return 'Lisa pilet'
      }
      if (this.$route.matched.some(({ name }) => name === 'login')) {
        return ''
      }
      if (this.$route.matched.some(({ name }) => name === 'regiser')) {
        return ''
      }
      if (this.$route.matched.some(({ name }) => name === 'profile')) {
        return 'Sinu konto'
      }

      return ''
    }
  }
}
</script>

<style scoped>
.header-wrapper {
  background: linear-gradient(101.77deg, #325490 0%, #006596 100%), #006494;
}
</style>
