import state from './chats.state'
import mutations from './chats.mutations'
import actions from './chats.actions'
import getters from './chats.getters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
