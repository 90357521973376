export default {
  general: {
    password: 'Parool',
    confirmPassword: 'Kinnita parool',
    login: 'Logi sisse',
    signup: 'Registreeru',
    signout: 'Logi välja',
    country: 'Riik',
    firstName: 'Eesnimi',
    lastName: 'Perekonnanimi',
    forgotPassword: 'Unustasin parooli',
    next: 'Edasi',
    back: 'Tagasi',
    selectCategory: 'Valige kategooria',
    success: 'Õnnestus',
    continue: 'Jätka',
    year: 'aasta',
    month: 'kuu',
    day: 'päev',
    hour: 'tund',
    minute: 'minut',
    second: 'sekund',
    ago: 'tagasi',
    cancel: 'Tühista',
    updatedSuccessfully: 'uuendatud edukalt'
  },
  navigationBar: {
    store: 'Pood'
  },
  loginForm: {
    rememberMe: 'Hoia mind sisselogituna'
  },
  registerForm: {
    emailRequired: 'E-mail on kohustuslik',
    emailMustBeValid: 'E-mail peab olema korrektne',
    emailInfo:
      'See on email, millega saate tulevikus Givverly kontole sisse logida',
    passwordInfo: 'Palun sisestage parool, miinimum',
    charactersLong: 'tähemärki pikk',
    minSixCharacters: 'Miinimum 6 tähemärki',
    minTwoCharacters: 'Miinimum 2 tähemärki',
    passwordsDontMatch: 'Paroolid ei kattu',
    createPassword: 'Loo parool',
    personalDetails: 'Personaalne info',
    personalDetailsInfo:
      'Need väljad on kohustuslikud, et tagada parim ja turvalisim kogemus kõigile. Seda infot on võimalik oma konto seadete alt muuta.',
    accountCreated: 'Konto loodud',
    countryRequired: 'Riik on kohustuslik'
  },
  changePasswordForm: {
    changePassword: 'Muuda parooli',
    currentPassword: 'Praegune parool',
    newPassword: 'Uus parool',
    change: 'Muuda'
  },
  searchbar: {
    enterKeyword: 'Sisestage märksõna'
  },
  profileMenuItems: {
    orders: 'Tehingud',
    pins: 'Pinnid',
    messages: 'Sõnumid',
    userPosts: 'Sinu tooted',
    userTickets: 'Sinu piletid',
    settings: 'Seaded'
  },
  emptyState: {
    noPostsDataTitle: 'Ühtegi tulemust ei leitud',
    noPostDataMessage: 'Proovige otsida midagi muud või tule hiljem tagasi',
    emailNotVerifiedTitle: 'Email ei ole kinnitatud',
    emailNotVerifiedDescription:
      'Kuulutuse lisamiseks tuleb Teil oma email kinnitada',
    sendVerificationLink: 'Saada uus link',
    noOrdersTitle: 'Tehingud puuduvad',
    noOrdersMessage:
      'Peale edukat ostu-müügitehingut kuvatakse need detailidega siia. Osta või müü midagi ja tule hiljem tagasi.',
    noPinsTitle: 'Pinnitud tooted puuduvad',
    noPinsMessage:
      'Peale toote pinnimist, ilmuvad pinnitud tooted siia. Pinnige toode ja tulge hiljem tagasi.',
    noMessagesTitle: 'Teil on 0 sõnumit',
    noMessagesMessage:
      'Kui keegi on teie poolt müüdavast tootest huvitatud, luuakse osapoolte vahel vestlused, mis ilmuvad siin.',
    noUserTicketsTitle1: 'Piletid puuduvad',
    noUserTicketsTitle2: 'Müüsite midagi?',
    noUserTicketsMessage1:
      'Uusi pileteid saate lisada, vajutades üleval paremas nurgas olevat + lista pilet nuppu',
    noUserTicketsMessage2:
      'Palun märkige oma toode müüduks, vajutades Märgi müüduks nuppu',
    addTicket: 'Lisa pilet',
    noOfflineSwiping: 'Internetiühendus puudub'
  },
  emailVerification: {
    successMessage:
      'Link edukalt saadetud! Juhiste saamiseks vaadake palun oma emaili'
  },
  stepper: {
    category: 'Kategooria',
    selectTitle: 'Sisestage pealkiri',
    title: 'Pealkiri',
    titleDescription: 'Mida lühem ja informatiivsem, seda parem',
    selectDescription: 'Sisestage kirjeldus',
    description: 'Kirjeldus',
    selectPicture: 'Valige foto(d)',
    pictureDescription: 'Foto(d) peab(vad) olema reaalsest tootest',
    selectPrice: 'Valige hind',
    price: 'Hind',
    givingAway: 'Annan tasuta ära',
    allowOffers: 'Pakkumised lubatud',
    other: 'Muu',
    location: 'Asukoht',
    enterLocation: 'Sisesta asukoht',
    additional: 'Lisa',
    shipping: 'Saatmine',
    byPost: 'Postiga',
    selectPacker: 'Valige kohaletoimetaja',
    pickup: 'Järele tulemine'
  },
  platformPayments: {
    info: 'Info',
    payment: 'Makse',
    status: 'Staatus',
    postLimitExceededMessage:
      'Te ületasite 3 postituse limiidi. Kas soovite osta lisa 3 postituse postitamise õiguse või piiramatu õiguse, et lisada piiramatu arv postitusi?',
    get3x: 'Osta 3x',
    getUnlimited: 'Osta Piiramatu',
    pay: 'Maksa',
    thankYouMessage: 'Aitäh! Teie ost oli edukas ning nüüd saate lisada',
    threeMore: 'veel 3',
    unlimited: 'piiramatult',
    listings: 'postitusi'
  },
  posts: {
    sortBy: 'Sorteeri',
    newest: 'Uuemad',
    oldest: 'Vanemad',
    priceLow: 'Odavamad',
    priceHigh: 'Kallimad',
    free: 'Tasuta',
    contactSeller: 'Võta müüjaga ühendust',
    profile: 'Profiil',
    share: 'Jaga',
    shareVia: 'Jaga',
    copyLink: 'Kopeeri link',
    openCloseDetails: 'Ava/Sulge detailid',
    delete: 'Kustuta',
    deactivate: 'Deaktiveeri',
    activateThisItem: 'Aktiveeri see pilet',
    markAsSold: 'Märgi müüduks',
    showHistory: 'Näita ajalugu',
    doYouReallyWantToDelete:
      'Kas olete kindel, et soovite selle pileti kustutada?',
    thisCanNotBeUndone: 'Seda ei saa tagasi võtta'
  },
  settings: {
    language: 'Keel',
    selectLanguage: 'Vali keel',
    shareYourProfile: 'Jaga oma profiili',
    pushNotifications: 'Teavitused',
    enable: 'Luba',
    disable: 'Keela',
    addBankAccountNumber: 'Lisa pangakonto number',
    addAccount: 'Lisa konto',
    addCreditCard: 'Lisa krediitkaart / deebetkaart',
    cardNumber: 'Kaardinumber',
    addCard: 'Lisa kaart',
    remove: 'Eemalda',
    addPhoneNumber: 'Lisa telefoninumber',
    countryCode: 'Riigikood',
    phoneNumber: 'Telefoninumber',
    add: 'Lisa',
    saveImage: 'Salvesta pilt',
    goToSettings: 'Mine seadetesse',
    addCardNotificationForSeller:
      'Et ostja saaks toote müügi eest sooritada ülekande, tuleb sul lisada oma pangakonto number seadete alt',
    addCardNotificationForBuyer:
      'Enne kui saad alustada maksmist asjade eest, tuleb sul lisada oma krediitkaart või deebetkaart seadete alt.',
    update: 'Uuenda'
  },
  messages: {
    allConversations: 'Kõik vestlused',
    writeAReply: 'Aa...',
    send: 'Saada',
    you: 'Sina',
    makeItSellableForTheUser: 'Märgi see toode antud kasutajale müüdavaks',
    makeItUnsellableForTheUser:
      'Märgi see toode antud kasutajale mittemüüdavaks',
    payForSelectedItem: 'Maksa toote eest',
    itemYouArePayingFor: 'Toode, mille eest maksate',
    sendingMoneyTo: 'Saaja nimi',
    amount: 'Summa',
    selectDeliveryMethod: 'Vali paki kättesaamis viis',
    faceToFace: 'Näost näkku',
    byAgreement: 'kokkuleppel',
    pickupLocation: 'Kättesaamis asukoht',
    selectYourPaymentMethod: 'Vali maksemeetod',
    faceToFacePickupLocationByAgreement:
      'Paki kättesaamine kokkuleppel Givverly sõnumites',
    pleaseMakeTransferAccordingToInfo:
      'Palun tehke ülekanne kasutades allolevat informatsiooni',
    explanation: 'Selgitus',
    bankAccountNumber: 'Pangakonto number',
    paid: 'Makstud'
  },
  categories: {
    womenClothes: 'Naisteriided',
    menClothes: 'Meesteriided',
    childrenClothes: 'Lasteriided',
    babyClothes: 'Beebiriided',
    babyAccessories: 'Beebitarvikud',
    collectiblesAndArt: 'Kollektsioon & Kunst',
    homeAndGarden: 'Kodu & Aed',
    fashion: 'Mood',
    electronics: 'Elektroonika',
    cars: 'Autod',
    carPartsAndAccessories: 'Auto Lisavarustus & Tarvikud',
    toysAndHobbies: 'Mänguasjad & Hobid',
    sportingGoods: 'Sporditarbed',
    other: 'Teised',
    all: 'Kõik'
  },
  statuses: {
    paid: 'makstud',
    delivered: 'käes'
  },
  dataIterator: {
    pageText: '{0}-{1} of {2}',
    noResultsText: 'No matching records found',
    loadingText: 'Loading items...'
  },
  dataTable: {
    itemsPerPageText: 'Rows per page:',
    ariaLabel: {
      sortDescending: ': Sorted descending. Activate to remove sorting.',
      sortAscending: ': Sorted ascending. Activate to sort descending.',
      sortNone: ': Not sorted. Activate to sort ascending.'
    }
  },
  dataFooter: {
    itemsPerPageText: 'Items per page:',
    itemsPerPageAll: 'All',
    nextPage: 'Next page',
    prevPage: 'Previous page',
    firstPage: 'First page',
    lastPage: 'Last page'
  },
  datePicker: {
    itemsSelected: '{0} selected'
  },
  noDataText: 'No data available',
  carousel: {
    prev: 'Previous visual',
    next: 'Next visual'
  },
  calendar: {
    moreEvents: '{0} more'
  }
}
