import EventsDB from '@/firebase/events-db'
import TicketsDB from '@/firebase/tickets-db'
import axios from 'axios'
// import '@firebase/functions'

export default {
  /**
   * Get event by id
   */
  getEventById: async ({ commit }, id) => {
    commit('app/setError', null, { root: true })
    commit(
      'app/setLoading',
      { type: 'getEventById', state: true },
      { root: true }
    )
    try {
      const event = await new EventsDB().read(id)
      commit('setEvent', event)

      commit('app/setError', null, { root: true })
      commit(
        'app/setLoading',
        { type: 'getEventById', state: false },
        { root: true }
      )
    } catch (error) {
      commit('app/setError', error, { root: true })
      commit(
        'app/setLoading',
        { type: 'getEventById', state: false },
        { root: true }
      )
      commit(
        'app/setSnackbar',
        {
          show: true,
          type: 'error',
          message: `Error while gettin event by id: ${error.message}`
        },
        { root: true }
      )
    }
  },
  /**
   * Fetch all tickets
   */
  getAllTickets: async ({ commit }, data) => {
    commit('app/setError', null, { root: true })
    commit(
      'app/setLoading',
      { type: 'getAllTickets', state: true },
      { root: true }
    )
    try {
      const tickets = await new TicketsDB().readAll(
        [...data.constraints],
        [...data.orderBy]
      )
      commit('setTickets', tickets)

      commit('app/setError', null, { root: true })
      commit(
        'app/setLoading',
        { type: 'getAllTickets', state: false },
        { root: true }
      )
    } catch (error) {
      commit('app/setError', error, { root: true })
      commit(
        'app/setLoading',
        { type: 'getAllTickets', state: false },
        { root: true }
      )
      commit(
        'app/setSnackbar',
        {
          show: true,
          type: 'error',
          message:
            process.env.NODE_END !== 'production'
              ? `Error while reading all tickets: ${error.message}`
              : 'Oops! Can not load tickets at the moment.'
        },
        { root: true }
      )
    }
  },
  /**
   * Fetch all user tickets
   */
  getUserTickets: async ({ commit, rootState }) => {
    commit('app/setError', null, { root: true })
    commit(
      'app/setLoading',
      { type: 'getAllUserTickets', state: true },
      { root: true }
    )
    try {
      const userTickets = await new TicketsDB().readAll(
        [['sellerId', '==', rootState.authentication.user.id]],
        ['createTimestamp', 'desc']
      )
      commit('setUserTickets', userTickets)

      commit('app/setError', null, { root: true })
      commit(
        'app/setLoading',
        { type: 'getAllUserTickets', state: false },
        { root: true }
      )
    } catch (error) {
      commit('app/setError', error, { root: true })
      commit(
        'app/setLoading',
        { type: 'getAllUserTickets', state: false },
        { root: true }
      )
      commit(
        'app/setSnackbar',
        {
          show: true,
          type: 'error',
          message:
            process.env.NODE_END !== 'production'
              ? `Error while reading user tickets: ${error.message}`
              : 'Oops! Can not load tickets at the moment.'
        },
        { root: true }
      )
    }
  },
  /**
   * Fetch all posts
   */
  getAllEvents: async ({ commit, getters }, constraints) => {
    commit('app/setError', null, { root: true })
    commit(
      'app/setLoading',
      { type: 'getAllEvents', state: true },
      { root: true }
    )
    try {
      const events = await new EventsDB().readAll(constraints, [
        'ticketsCount',
        'desc'
      ])
      commit('setEvents', events)
      commit('setLastVisibleEvent', getters.events)

      commit('app/setError', null, { root: true })
      commit(
        'app/setLoading',
        { type: 'getAllEvents', state: false },
        { root: true }
      )
    } catch (error) {
      commit('app/setError', error, { root: true })
      commit(
        'app/setLoading',
        { type: 'getAllEvents', state: false },
        { root: true }
      )
      commit(
        'app/setSnackbar',
        {
          show: true,
          type: 'error',
          message:
            process.env.NODE_END !== 'production'
              ? `Error while reading all events: ${error.message}`
              : 'Oops! Can not load events at the moment.'
        },
        { root: true }
      )
    }
  },
  /**
   * Fetch more posts
   */
  readMorePosts: async ({ commit, state, getters }) => {
    commit('app/setError', null, { root: true })
    commit(
      'app/setLoading',
      { type: 'readMorePosts', state: true },
      { root: true }
    )
    try {
      const posts = await new EventsDB().readMorePosts()
      commit('removePostById', state.lastVisiblePost.id)
      posts.forEach(post => {
        commit('addPost', post)
      })
      commit('setLastVisiblePost', getters.posts)

      commit('app/setError', null, { root: true })
      commit(
        'app/setLoading',
        { type: 'readMorePosts', state: false },
        { root: true }
      )
    } catch (error) {
      commit('app/setError', error, { root: true })
      commit(
        'app/setLoading',
        { type: 'readMorePosts', state: false },
        { root: true }
      )
      commit(
        'app/setSnackbar',
        {
          show: true,
          type: 'error',
          message:
            process.env.NODE_END !== 'production'
              ? `Error while reading more posts: ${error.message}`
              : 'Oops! Can not load posts at the moment.'
        },
        { root: true }
      )
    }
  },
  /**
   * Fetch sold posts by user id
   */
  getSoldPostsByUserId: async ({ commit }, userId) => {
    return new Promise((resolve, reject) => {
      commit('app/setError', null, { root: true })
      commit(
        'app/setLoading',
        { type: 'getSoldPostsByUserId', state: true },
        { root: true }
      )

      new EventsDB()
        .readAll([
          ['userId', '==', userId],
          ['isSold', '==', true]
        ])
        .then(posts => {
          commit('app/setError', null, { root: true })
          commit(
            'app/setLoading',
            { type: 'getSoldPostsByUserId', state: false },
            { root: true }
          )
          resolve(posts)
        })
        .catch(error => {
          commit(
            'app/setSnackbar',
            {
              show: true,
              type: 'error',
              message:
                process.env.NODE_END !== 'production'
                  ? `Error while getting sold posts by user id: ${error.message}`
                  : 'Oops! Can not load posts at the moment.'
            },
            { root: true }
          )
          commit(
            'app/setLoading',
            { type: 'getSoldPostsByUserId', state: false },
            { root: true }
          )
          reject(error)
        })
    })
  },
  /**
   * Fetch posts by user id
   */
  getPostsByUserId: async ({ commit }, userId) => {
    return new Promise((resolve, reject) => {
      commit('app/setError', null, { root: true })
      commit(
        'app/setLoading',
        { type: 'getPostsByUserId', state: true },
        { root: true }
      )

      new EventsDB()
        .readAll([['userId', '==', userId]])
        .then(posts => {
          commit('app/setError', null, { root: true })
          commit(
            'app/setLoading',
            { type: 'getPostsByUserId', state: false },
            { root: true }
          )
          commit('setUserPosts', posts)
          resolve(posts)
        })
        .catch(error => {
          commit('app/setError', error, { root: true })
          commit(
            'app/setLoading',
            { type: 'getPostsByUserId', state: false },
            { root: true }
          )
          reject(error)
        })
    })
  },
  /**
   * Fetch posts of current loggedin user
   */
  getCurrentUserPosts: async ({ commit, rootState }) => {
    if (rootState.posts.userPosts.length <= 0) {
      commit('app/setError', null, { root: true })
      commit(
        'app/setLoading',
        { type: 'getCurrentUserPosts', state: true },
        { root: true }
      )
      await new EventsDB()
        .readAll([['userId', '==', rootState.authentication.user.id]])
        .then(posts => {
          commit('setUserPosts', posts)
          commit('app/setError', null, { root: true })
          commit(
            'app/setLoading',
            { type: 'getCurrentUserPosts', state: false },
            { root: true }
          )
        })
        .catch(error => {
          commit('app/setError', error, { root: true })
          commit(
            'app/setLoading',
            { type: 'getCurrentUserPosts', state: false },
            { root: true }
          )
          commit(
            'app/setSnackbar',
            {
              show: true,
              type: 'error',
              message:
                process.env.NODE_END !== 'production'
                  ? `Error while getting current user posts: ${error.message}`
                  : 'Something went wrong! We could not load your posts.'
            },
            { root: true }
          )
        })
    }
  },
  /**
   * Create a post
   */
  createPost: async ({ commit }, post) => {
    commit('app/setError', null, { root: true })
    commit(
      'app/setLoading',
      { type: 'createPost', state: false },
      { root: true }
    )
    const postsDB = new EventsDB()
    // const postExists = await postsDB.read(post.data.id).then(response => {
    //   return response.exists
    // })
    const createdPost = post.data.id
      ? await postsDB.create(post.data)
      : await postsDB.update(post.data)

    return createdPost
  },
  /**
   * Create Event
   */
  createEvent: async ({ commit }, event) => {
    if (!event.data.id) return
    commit('app/setError', null, { root: true })
    commit(
      'app/setLoading',
      { type: 'createEvent', state: false },
      { root: true }
    )
    const eventsDB = new EventsDB()

    const createdEvent = await eventsDB
      .create({ id: event.data.id, ...event.data }, event.data.id)
      .then(() => {
        commit(
          'app/setLoading',
          { type: 'createEvent', state: false },
          { root: true }
        )

        commit(
          'app/setSnackbar',
          {
            show: true,
            type: 'success',
            message: 'Ürituse lisamine oli edukas.'
          },
          { root: true }
        )
      })
      .catch(err => {
        commit('app/setError', err, { root: true })
        commit(
          'app/setLoading',
          { type: 'createEvent', state: false },
          { root: true }
        )
        commit(
          'app/setSnackbar',
          {
            show: true,
            type: 'error',
            message:
              process.env.NODE_END !== 'production'
                ? `Error while creating a event: ${createdEvent} `
                : 'Midagi läks nässu.'
          },
          { root: true }
        )
      })
  },
  /**
   * Create a ticket
   */
  createTicket: async ({ commit }, ticket) => {
    commit('app/setError', null, { root: true })
    commit(
      'app/setLoading',
      { type: 'createTicket', state: false },
      { root: true }
    )
    const ticketsDB = new TicketsDB()
    const createdTicket =
      ticket.requestType === 'create'
        ? await ticketsDB
            .create({ ...ticket.data }, ticket.data.id)
            .then(() => {
              if (
                !ticket.data.id ||
                ticket.data.id === null ||
                ticket.data.id === ''
              ) {
                commit('addUserTicket', createdTicket, { root: true })
              }
              commit('app/setError', null, { root: true })
              commit(
                'app/setLoading',
                { type: 'createTicket', state: false },
                { root: true }
              )
              commit(
                'app/setSnackbar',
                {
                  show: true,
                  type: 'success',
                  message: 'Pilet sisestatud edukalt.'
                },
                { root: true }
              )
            })
            .catch(error => {
              commit('app/setError', error, { root: true })
              commit(
                'app/setLoading',
                { type: 'createTicket', state: false },
                { root: true }
              )
              commit(
                'app/setSnackbar',
                {
                  show: true,
                  type: 'error',
                  message:
                    process.env.NODE_END !== 'production'
                      ? `Error while creating a ticket: ${error.message}`
                      : 'Something went wrong! Please try again later.'
                },
                { root: true }
              )
            })
        : await ticketsDB
            .update(ticket.data)
            .then(() => {
              if (
                !ticket.data.id ||
                ticket.data.id === null ||
                ticket.data.id === ''
              ) {
                commit('addUserTicket', createdTicket, { root: true })
              }
              commit('app/setError', null, { root: true })
              commit(
                'app/setLoading',
                { type: 'createTicket', state: false },
                { root: true }
              )
              commit(
                'app/setSnackbar',
                {
                  show: true,
                  type: 'success',
                  message:
                    !ticket.data.id ||
                    ticket.data.id === null ||
                    ticket.data.id === ''
                      ? 'Ticket created successfully.'
                      : 'Hind edukalt uuendatud'
                },
                { root: true }
              )
            })
            .catch(error => {
              commit('app/setError', error, { root: true })
              commit(
                'app/setLoading',
                { type: 'createTicket', state: false },
                { root: true }
              )
              commit(
                'app/setSnackbar',
                {
                  show: true,
                  type: 'error',
                  message:
                    process.env.NODE_END !== 'production'
                      ? `Error while updating a ticket: ${error.message}`
                      : 'Something went wrong! Please try again later.'
                },
                { root: true }
              )
            })
  },

  /**
   * Mark post as sold
   */
  markAsSold: async ({ commit }, id) => {
    return new Promise((resolve, reject) => {
      commit('app/setError', null, { root: true })
      commit(
        'app/setLoading',
        { type: 'markAsSold', state: true },
        { root: true }
      )
      new TicketsDB()
        .update({
          id,
          status: 'sold'
        })
        // .then(() => {
        //   const newOrder = {
        //     allowOffers: payload.post.allowOffers,
        //     anonymous: payload.post.anonymous,
        //     category: payload.post.category,
        //     createTimestamp: payload.post.createTimestamp,
        //     description: payload.post.description,
        //     id: payload.post.id,
        //     images: payload.post.images,
        //     isActive: false,
        //     isSold: true,
        //     location: payload.post.location,
        //     price: payload.post.price,
        //     isFree: payload.post.isFree,
        //     shipping: payload.post.shipping,
        //     title: payload.post.title,
        //     updateTimestamp: payload.post.updateTimestamp,
        //     userId: payload.post.userId,
        //     status: 'sold'
        //   }
        //   new OrdersDB(payload.soldTo).create(newOrder)
        // })
        .then(() => {
          commit('markAsSold', id)
          commit('app/setError', null, { root: true })
          commit(
            'app/setLoading',
            { type: 'markAsSold', state: false },
            { root: true }
          )
          resolve()
        })
        .catch(error => {
          commit('app/setError', error, { root: true })
          commit(
            'app/setLoading',
            { type: 'markAsSold', state: false },
            { root: true }
          )
          reject(error)
        })
    })
  },

  /**
   * Delete post (can not be undone)
   */
  deleteTicket: async ({ commit }, ticketId) => {
    const ticketsDB = new TicketsDB(ticketId)

    commit('app/setError', null, { root: true })
    commit(
      'app/setLoading',
      { type: 'deleteTicket', state: true },
      { root: true }
    )

    try {
      await ticketsDB.update({
        id: ticketId,
        status: 'inactive'
      })
      commit('deleteTicket', ticketId)
      commit(
        'app/setLoading',
        { type: 'deleteTicket', state: false },
        { root: true }
      )
      commit(
        'app/setSnackbar',
        {
          show: true,
          type: 'success',
          message: 'Pileti kustutamine õnnestus!'
        },
        { root: true }
      )
    } catch (error) {
      commit('app/setError', error, { root: true })
      commit(
        'app/setLoading',
        { type: 'deleteTicket', state: false },
        { root: true }
      )
      commit(
        'app/setSnackbar',
        {
          show: true,
          type: 'error',
          message:
            process.env.NODE_END !== 'production'
              ? `Error while deleting ticket: ${error.message}`
              : 'Something went wrong! Please try again later.'
        },
        { root: true }
      )
    }
  },
  /**
   * Deactivate post (can be activated with "activatePost")
   */
  deactivateTicket: async ({ commit }, ticketId) => {
    const ticketsDB = new TicketsDB(ticketId)

    commit('app/setError', null, { root: true })
    commit(
      'app/setLoading',
      { type: 'deactivateTicket', state: true },
      { root: true }
    )

    try {
      await ticketsDB.update({
        id: ticketId,
        status: 'deactivated'
      })
      commit('deactivateTicket', ticketId)
      commit(
        'app/setLoading',
        { type: 'deactivateTicket', state: false },
        { root: true }
      )
      commit(
        'app/setSnackbar',
        {
          show: true,
          type: 'success',
          message: 'Pileti deaktiveerimine õnnestus!'
        },
        { root: true }
      )
    } catch (error) {
      commit('app/setError', error, { root: true })
      commit(
        'app/setLoading',
        { type: 'deactivateTicket', state: false },
        { root: true }
      )
      commit(
        'app/setSnackbar',
        {
          show: true,
          type: 'error',
          message:
            process.env.NODE_END !== 'production'
              ? `Error while deactivating ticket: ${error.message}`
              : 'Something went wrong! Please try again later.'
        },
        { root: true }
      )
    }
  },
  /**
   * Activate post (can be deactivated with "deactivatePost")
   */
  activateTicket: async ({ commit }, ticketId) => {
    const postsDb = new TicketsDB(ticketId)

    commit('app/setError', null, { root: true })
    commit(
      'app/setLoading',
      { type: 'activateTicket', state: true },
      { root: true }
    )

    try {
      await postsDb.update({
        id: ticketId,
        status: 'active'
      })
      commit('activateTicket', ticketId)
      commit(
        'app/setLoading',
        { type: 'activateTicket', state: false },
        { root: true }
      )
      commit(
        'app/setSnackbar',
        {
          show: true,
          type: 'success',
          message: 'Pileti aktiveerimine õnnestus!'
        },
        { root: true }
      )
    } catch (error) {
      commit('app/setError', error, { root: true })
      commit(
        'app/setLoading',
        { type: 'activateTicket', state: false },
        { root: true }
      )
      commit(
        'app/setSnackbar',
        {
          show: true,
          type: 'error',
          message:
            process.env.NODE_END !== 'production'
              ? `Error while activating ticket: ${error.message}`
              : 'Something went wrong! Please try again later.'
        },
        { root: true }
      )
    }
  },
  /**
   * Upload files
   */

  uploadFile({ commit }, payload) {
    commit('app/setError', null, { root: true })
    commit(
      'app/setLoading',
      { type: 'uploadFile', state: true },
      { root: true }
    )
    axios({
      method: 'POST',
      url: `https://europe-west1-${process.env.VUE_APP_FIREBASE_PROJECT_ID}.cloudfunctions.net/uploadTicketAndGetData`,
      // url: `http://localhost:5001/piletivahetus-develop/europe-west1/uploadTicketAndGetData`,
      headers: [
        {
          'Content-Type': 'multipart/form-data'
        }
      ],
      data: payload.data
    })
      .then(response => {
        if (response.data.length) {
          // commit('addUploadedTicket', {
          //   ...response.data[0],
          //   filename: payload.fileName
          // })
          response.data.forEach(ticket => {
            commit('addNewTicketsToUserAddedTicketsArray', {
              ...ticket,
              filename: payload.fileName,
              ticketType: '',
              seatDetails: '',
              seatSelected: false,
              price: Number(0)
            })
          })
          commit('app/setError', null, { root: true })
          commit(
            'app/setLoading',
            { type: 'uploadFile', state: false },
            { root: true }
          )
        }
      })
      .catch(error => {
        commit(
          'app/setSnackbar',
          {
            show: true,
            type: 'error',
            message:
              process.env.NODE_END === 'production'
                ? `Failist piletit ei leitud!`
                : `Viga: ${error}`
          },
          { root: true }
        )
        commit('app/setError', error, { root: true })
        commit(
          'app/setLoading',
          { type: 'uploadFile', state: false },
          { root: true }
        )
      })
  }
}
