import GenericDB from './generic-db'

export default class TokensDB extends GenericDB {
  constructor(userId) {
    // eslint-disable-next-line no-unused-expressions
    userId ? super(`tokens/${userId}`) : super('tokens')
  }

  // Here you can extend TokensDB with custom methods
}
