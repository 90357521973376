export default {
  addNewSource: (state, source) => {
    state.sources.push(source)
  },
  setSources: (state, sources) => {
    state.sources = sources
  },
  removeSourceById: (state, sourceToDeleteId) => {
    const index = state.sources.findIndex(
      source => source.id === sourceToDeleteId
    )
    state.sources.splice(index, 1)
  }
}
