<template>
  <v-container class="pa-3 row elevation-0">
    <v-text-field
      ref="searchInput"
      class="search-input mb-4 primary-shadow"
      solo
      hide-details
      rounded
      label="Sisestage märksõna"
      aria-label="Search"
      @input="searchDidChange"
    >
      <v-icon slot="append">mdi-magnify</v-icon>
    </v-text-field>
    <!-- <v-flex xs12 d-flex justify-end align-center class="mb-12">
      <v-menu left offset-y nudge-bottom="10">
        <template v-slot:activator="{ on }">
          <v-btn color="primary" rounded small class="mt-2" v-on="on">
            {{ $vuetify.lang.t('$vuetify.posts.sortBy') }}
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(item, index) in [
              {
                title: $vuetify.lang.t('$vuetify.posts.newest'),
                value: 'desc',
                sortBy: 'createTimestamp'
              },
              {
                title: $vuetify.lang.t('$vuetify.posts.oldest'), 
                value: 'asc',
                sortBy: 'createTimestamp'
              },
              {
                title: $vuetify.lang.t('$vuetify.posts.priceLow'),
                value: 'asc',
                sortBy: 'price'
              },
              {
                title: $vuetify.lang.t('$vuetify.posts.priceHigh'),
                value: 'desc',
                sortBy: 'price'
              }
            ]"
            :key="index"
            @click="didSelectSortOption(item)"
          >
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-flex> -->

    <v-overlay :value="loading.state" color="rgba(255, 223, 223,1)">
      <v-btn icon @click="overlay = false">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-btn>
    </v-overlay>

    <v-row v-if="loading.state === false">
      <v-col v-for="item in events" :key="item.id" cols="12" sm="3" md="3">
        <event-card :item="item" />
      </v-col>
      <v-row class="d-flex align-center justify-center my-12">
        <empty-state-card
          v-if="loading.state === false && events.length <= 0"
          :key="'empty-state'"
          :title="$vuetify.lang.t('$vuetify.emptyState.noPostsDataTitle')"
          :icon="'mdi-format-list-text'"
          color="#003650"
          :message="'Ühtegi piletit ei leitud!'"
          :action-text="'Lisa pilet'"
          class="empty-state"
          @on-cta-click="$router.replace('/lisa-pilet')"
        />
      </v-row>
    </v-row>
    <v-row
      v-if="loading.state === true && loading.type === 'getAllEvents'"
      class="fill-height ma-0"
      align="center"
      justify="center"
    >
      <v-progress-circular
        indeterminate
        color="grey lighten-5"
        style="min-height: 80vh;"
      ></v-progress-circular>
    </v-row>
  </v-container>
</template>

<script>
import EventCard from '@/components/Event/EventCard'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'Landing',
  components: {
    EventCard,
    EmptyStateCard: () =>
      import(
        /* webpackChunkName: "EmptyStateCard" */ '@/components/EmptyStateCard'
      )
  },
  data() {
    return {
      overlay: true,
      searchBarOpened: false
    }
  },
  computed: {
    ...mapGetters('events', ['events', 'tickets']),
    ...mapGetters('app', ['loading'])
  },
  created() {
    window.scrollTo(0, 0)
    this.getAllEvents([['ticketsCount', '>', 0]])
  },
  methods: {
    ...mapActions('events', ['getAllEvents']),
    ...mapMutations('app', ['setSearchString']),
    searchDidChange(val) {
      this.setSearchString(val)
    },
    didSelectSortOption(item) {
      this.$store.commit('events/setSelectedSortOrder', item)
    }
  },
  head: {
    title() {
      return {
        inner: 'Piletivahetus.ee - Vahetame elamusi inimeselt inimesele'
      }
    },
    meta: [
      {
        name: 'description',
        content:
          'Kas Sa jäid pileti ostamisega hiljaks? Või just sooviksid oma teatri, kontserti pileti edasi müüa? Piletivahetus aitab!',
        id: 'desc'
      },
      {
        name: 'robots',
        content: 'index, follow'
      },
      { p: 'og:url', c: 'https://piletivahetus.ee' },
      { p: 'og:type', c: 'website' },
      {
        p: 'og:title',
        c: 'Piletivahetus.ee - Vahetame elamusi inimeselt inimesele'
      },
      {
        p: 'og:image',
        c:
          'https://firebasestorage.googleapis.com/v0/b/piletivahetus-develop.appspot.com/o/misc%2F1200x630_FB_piletivahetus_min.png?alt=media'
      },
      { p: 'og:image:width', c: '1200' },
      { p: 'og:image:height', c: '630' }
    ],
    link: [
      { rel: 'canonical', href: 'https://piletivahetus.ee' },
      {
        rel: 'icon',
        href:
          'https://firebasestorage.googleapis.com/v0/b/piletivahetus-develop.appspot.com/o/misc%2Ffavicon.png?alt=media',
        sizes: '16x16',
        type: 'image/png'
      }
    ]
  }
}
</script>
<style lang="scss">
.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
  > .v-input__control
  > .v-input__slot {
  box-shadow: none;
}
</style>
