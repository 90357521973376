export default {
  appTitle: process.env.VUE_APP_TITLE,
  snackbar: false,
  error: null,
  loading: { type: null, state: false },
  networkOnLine: true,
  SWRegistrationForNewContent: null,
  showAddToHomeScreenModalForApple: false,
  refreshingApp: false,
  selectedCategory: 'All',
  selectedProfileMenuItem: 'Messages',
  searchBarOpened: false,
  searchString: '',
  selectedLocation: {
    city: 'Tallinn',
    country: 'Estonia',
    lat: 59.4339,
    lng: 24.728
  }
}
