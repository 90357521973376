export default {
  general: {
    password: 'Password',
    confirmPassword: 'Confirm password',
    login: 'Login',
    signup: 'Sign up',
    signout: 'Sign out',
    country: 'Country',
    firstName: 'First name',
    lastName: 'Last name',
    forgotPassword: 'Forgot password',
    next: 'Next',
    back: 'Back',
    selectCategory: 'Select category',
    success: 'Success',
    continue: 'Continue',
    year: 'year',
    month: 'month',
    day: 'day',
    hour: 'hour',
    minute: 'minute',
    second: 'second',
    ago: 'ago',
    cancel: 'Cancel',
    updatedSuccessfully: 'updated successfully'
  },
  navigationBar: {
    store: 'Store'
  },
  loginForm: {
    rememberMe: 'Keep me looged in'
  },
  registerForm: {
    emailRequired: 'E-mail is required',
    emailMustBeValid: 'E-mail must be valid',
    emailInfo:
      'This is the email you will use to login to your Givverly account',
    passwordInfo: 'Please enter a password for your account, minimum of',
    charactersLong: 'characters long',
    minSixCharacters: 'Min 6 characters',
    minTwoCharacters: 'Min 2 characters',
    passwordsDontMatch: 'Passwords do not match',
    createPassword: 'Create password',
    personalDetails: 'Personal details',
    personalDetailsInfo:
      'These fields are required to make the experience perfect for You. It is also possible to change it later from your settings.',
    accountCreated: 'Account created',
    countryRequired: 'Country must be selected'
  },
  changePasswordForm: {
    changePassword: 'Change password',
    currentPassword: 'Current password',
    newPassword: 'New password',
    change: 'Change'
  },
  searchbar: {
    enterKeyword: 'Enter Your keyword'
  },
  profileMenuItems: {
    orders: 'Orders',
    pins: 'Pins',
    messages: 'Messages',
    userPosts: 'Your listings',
    userTickets: 'Your tickets',
    settings: 'Settings'
  },
  emptyState: {
    noPostsDataTitle: 'No results found',
    noPostDataMessage:
      'Try adjusting your search to find what You are looking for or check back later for new items',
    emailNotVerifiedTitle: 'Email not verified',
    emailNotVerifiedDescription:
      'In order to add any listings you have to verify your email you registered with',
    sendVerificationLink: 'Resend verification link',
    noOrdersTitle: 'No orders',
    noOrdersMessage:
      'After successful purchase, all your orders are going to appear here. Go buy some items and come back later.',
    noPinsTitle: 'No pinned items',
    noPinsMessage:
      'After pinning an item, all your pinned items are going to appear here. Go swipe some cards and come back later.',
    noMessagesTitle: 'You have 0 messages',
    noMessagesMessage:
      'If somebody is interested in yout listed item, chat will be created and it will appear here.',
    noUserTicketsTitle1: 'No tickets',
    noUserTicketsTitle2: 'Sold something?',
    noUserTicketsMessage1:
      'You can add new tickets by clicking the + button at top right',
    noUserTicketsMessage2:
      'Please mark your item as sold by clicking the Mark as sold button',
    addTicket: 'Add ticket'
  },
  emailVerification: {
    successMessage:
      'Verification link sent successfully! Check your email for instructions'
  },
  stepper: {
    category: 'Category',
    selectTitle: 'Select title',
    title: 'Title',
    titleDescription: 'The shorter and more informative, the better',
    selectDescription: 'Select description',
    description: 'Description',
    selectPicture: 'Select picture(s)',
    pictureDescription: 'Picture must be of a real product',
    selectPrice: 'Select price',
    price: 'Price',
    givingAway: 'Giving away for free',
    allowOffers: 'Allow offers',
    other: 'Other',
    location: 'Location',
    enterLocation: 'Enter location',
    additional: 'Additional',
    shipping: 'Shipping',
    byPost: 'By post',
    selectPacker: 'Select packer',
    pickup: 'Pickup'
  },
  platformPayments: {
    info: 'Info',
    payment: 'Payment',
    status: 'Status',
    postLimitExceededMessage:
      'You exceeded the 3 post limit. It costs You 2€ to add 3 more listings or 5€ to add unlimited listings.',
    get3x: 'Get 3x',
    getUnlimited: 'Get Unlimited',
    pay: 'Pay',
    thankYouMessage:
      'Thank you! Your purchase was successful. You can now list',
    threeMore: '3 more',
    unlimited: 'unlimited',
    listings: 'listings'
  },
  posts: {
    sortBy: 'Sort by',
    newest: 'Newest',
    oldest: 'Oldest',
    priceLow: 'Price low',
    priceHigh: 'Price high',
    free: 'Free',
    contactSeller: 'Contact seller',
    profile: 'Profile',
    share: 'Share',
    shareVia: 'Share via',
    copyLink: 'Copy link',
    openCloseDetails: 'Open/Close details',
    delete: 'Delete',
    deactivate: 'Deactivate',
    activateThisItem: 'Activate this ticket',
    markAsSold: 'Mark as sold',
    showHistory: 'Show history',
    doYouReallyWantToDelete: 'Do you really want to delete the post?',
    thisCanNotBeUndone: 'This can not be undone'
  },
  settings: {
    language: 'Language',
    selectLanguage: 'Select language',
    shareYourProfile: 'Share your profile',
    pushNotifications: 'Push notifications',
    enable: 'Enable',
    disable: 'Enable',
    addBankAccountNumber: 'Add bank account number',
    addAccount: 'Add account',
    addCreditCard: 'Add credit card / debit card',
    cardNumber: 'Card number',
    addCard: 'Add card',
    remove: 'Remove',
    addPhoneNumber: 'Add phone number',
    countryCode: 'Country code',
    phoneNumber: 'Phone number',
    add: 'Add',
    saveImage: 'Save image',
    goToSettings: 'Go to settings',
    addCardNotificationForSeller:
      'Before you can start collecting money from your sellings, you gotta add your bank account number under your profile settings.',
    addCardNotificationForBuyer:
      'Before you can start sending money for your purchases, you gotta add your credit card under your profile settings.',
    update: 'Update'
  },
  messages: {
    allConversations: 'All conversations',
    writeAReply: 'Aa...',
    send: 'Saada',
    you: 'You',
    makeItSellableForTheUser: 'Make it sellable for the user',
    makeItUnsellableForTheUser: 'Make it unsellable for the user',
    payForSelectedItem: 'Pay for selected item',
    itemYouArePayingFor: 'Item you are paying for',
    sendingMoneyTo: 'Sending money to',
    amount: 'Amount',
    selectDeliveryMethod: 'Select delivery method',
    faceToFace: 'Face to Face',
    byAgreement: 'by agreement',
    pickupLocation: 'Pickup location',
    selectYourPaymentMethod: 'Select payment method',
    faceToFacePickupLocationByAgreement:
      'Pickup location by agree on Givverly messages',
    pleaseMakeTransferAccordingToInfo:
      'Please make a transfer using the information below',
    explanation: 'Explanation',
    bankAccountNumber: 'Bank account number',
    paid: 'Paid'
  },
  categories: {
    womenClothes: 'Women clothes',
    menClothes: 'Men clothes',
    childrenClothes: 'Children clothes',
    babyClothes: 'Baby clothes',
    babyAccessories: 'Baby accessories',
    collectiblesAndArt: 'Collectibles & Art',
    homeAndGarden: 'Home & Garden',
    fashion: 'Fashion',
    electronics: 'Electronics',
    cars: 'Cars',
    carPartsAndAccessories: 'Car Parts & Accessories',
    toysAndHobbies: 'Toys & Hobbies',
    sportingGoods: 'SportingGoods',
    other: 'Other',
    all: 'All'
  },
  statuses: {
    paid: 'paid',
    delivered: 'delivered'
  },
  dataIterator: {
    pageText: '{0}-{1} of {2}',
    noResultsText: 'No matching records found',
    loadingText: 'Loading items...'
  },
  dataTable: {
    itemsPerPageText: 'Rows per page:',
    ariaLabel: {
      sortDescending: ': Sorted descending. Activate to remove sorting.',
      sortAscending: ': Sorted ascending. Activate to sort descending.',
      sortNone: ': Not sorted. Activate to sort ascending.'
    }
  },
  dataFooter: {
    itemsPerPageText: 'Items per page:',
    itemsPerPageAll: 'All',
    nextPage: 'Next page',
    prevPage: 'Previous page',
    firstPage: 'First page',
    lastPage: 'Last page'
  },
  datePicker: {
    itemsSelected: '{0} selected'
  },
  noDataText: 'No data available',
  carousel: {
    prev: 'Previous visual',
    next: 'Next visual'
  },
  calendar: {
    moreEvents: '{0} more'
  }
}
