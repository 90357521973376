// import { isNil } from 'lodash'
import { firebase } from '@firebase/app'
import GenericDB from './generic-db'
import firestore from './async-firestore'

export default class UsersDB extends GenericDB {
  constructor() {
    super('users')
  }

  /**
   * Rate user
   * @param userId
   * @param rating
   */
  async rateUser(userId, rating) {
    const collectionRef = (await firestore()).collection(
      `${this.collectionPath}/${userId}/ratings`
    )
    const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp()

    const dataToCreate = {
      rating,
      createTimestamp: serverTimestamp,
      updateTimestamp: serverTimestamp
    }

    const createPromise = collectionRef.add(dataToCreate).then(doc => doc.id)

    const docId = await createPromise

    return {
      id: docId,
      rating,
      createTimestamp: new Date(),
      updateTimestamp: new Date()
    }
  }

  /**
   * Get user rating
   * @param userId
   * @param rating
   */
  async getUserRating(userId) {
    // const result = await (await firestore())
    //   .collection(`${this.collectionPath}/${userId}/ratings`)
    //   .read()

    // const data = result.exists ? result.data() : null

    // if (isNil(data)) return null

    // this.convertObjectTimestampPropertiesToDate(data)
    // let total = 0.0
    // let count = 0
    // let average = 0.0
    // console.log(data)
    // data.forEach(element => {
    //   total = element.rating
    //   count += 1
    //   average = total / count
    // })
    // return { id: userId, rating: average }

    // const collectionRef = (await firestore()).collection(
    //   `${this.collectionPath}/${userId}/ratings`
    // )
    // const query = collectionRef.orderBy('createTimestamp', 'desc')

    // let total = 0.0
    // let count = 0
    // let average = 0.0

    // return query.get().then(result => {
    //   result.docs.forEach(element => {
    //     total = element.rating
    //     count += 1
    //     average = total / count
    //   })
    //   return { id: userId, rating: average }
    // })

    const collectionRef = (await firestore()).collection(
      `${this.collectionPath}/${userId}/ratings`
    )
    const query = collectionRef.orderBy('createTimestamp', 'desc')

    const formatResult = result =>
      result.docs.map(ref =>
        this.convertObjectTimestampPropertiesToDate({
          id: ref.id,
          ...ref.data()
        })
      )
    return query.get().then(formatResult)
  }

  async getUserRating1(userId) {
    const collectionRef = (await firestore()).collection(
      `${this.collectionPath}/${userId}/ratings`
    )
    const query = collectionRef.orderBy('createTimestamp', 'desc')

    let total = 0.0
    let count = 0
    let average = 0.0

    return query.get().then(result => {
      result.data().forEach(element => {
        total = element.rating
        count += 1
        average = total / count
      })
      return { id: userId, rating: average }
    })
  }
}
