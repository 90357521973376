/* eslint-disable import/extensions */
import state from './payments.state'
import mutations from './payments.mutations'
import actions from './payments.actions'
import getters from './payments.getters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
