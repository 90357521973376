import store from '../store'
import GenericDB from './generic-db'
import firestore from './async-firestore'

// [Optional] Extend GenericDB if you want CRUD operations
export default class EventsDB extends GenericDB {
  constructor() {
    super('events')
  }

  // Here you can extend PostsDB with custom methods
  async readAll(constraints = null, orderBy) {
    const collectionRef = (await firestore())
      .collection(this.collectionPath)
      .orderBy(...orderBy)
    let query = collectionRef

    if (constraints) {
      constraints.forEach(constraint => {
        query = query.where(...constraint)
      })
    }

    const formatResult = result =>
      result.docs.map(ref =>
        this.convertObjectTimestampPropertiesToDate({
          id: ref.id,
          ...ref.data()
        })
      )

    return query.get().then(formatResult)
  }

  async readMorePosts(constraints = null) {
    const collectionRef = (await firestore()).collection(this.collectionPath)
    let query = collectionRef
      .orderBy(
        store.state.posts.selectedSortOrder.sortBy,
        store.state.posts.selectedSortOrder.value
      )
      .startAfter(store.state.posts.lastVisiblePost.createTimestamp)
      .limit(10)
    if (constraints) {
      constraints.forEach(constraint => {
        query = query.where(...constraint)
      })
    }

    const formatResult = result =>
      result.docs.map(ref =>
        this.convertObjectTimestampPropertiesToDate({
          id: ref.id,
          ...ref.data()
        })
      )
    return query.get().then(formatResult)
  }

  // helper to get the last document in a QuerySnapShot
  getLastDoc = snap => {
    if (snap.empty) return null
    return snap.docs[snap.docs.length - 1]
  }
}
