import { firebase } from '@firebase/app'
import { isNil } from 'lodash'

import store from '@/store'

firebase.auth().onAuthStateChanged(firebaseUser => {
  const actionToDispatch = isNil(firebaseUser) ? 'logout' : 'autoLogin'

  store.dispatch(`authentication/${actionToDispatch}`, firebaseUser)
})
