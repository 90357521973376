var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"d-flex align-start pl-md-10 flex-column pl-4",class:{ 'header-wrapper': _vm.full === false },attrs:{"id":"header-wrapper","fluid":""}},[_c('v-row',{staticClass:"mx-auto",staticStyle:{"width":"100%"}},[_c('v-col',{staticClass:"pb-0 pr-6 pl-1 pl-md-0 pr-md-0",attrs:{"cols":"7"}},[_c('router-link',{attrs:{"to":'/'}},[_c('v-img',{attrs:{"src":require('../assets/img/Piletivahetuse_logo_valge.svg'),"lazy-src":require('../assets/img/Piletivahetuse_logo_valge.svg'),"max-width":"244"}})],1)],1),_c('v-col',{staticClass:"pb-0 pl-0",style:(_vm.$vuetify.breakpoint.smAndDown ? 'padding-right:0;' : ''),attrs:{"cols":"5"}},[(!_vm.isAuthenticated)?_c('v-btn',{staticClass:"float-right white--text text-capitalize",attrs:{"text":"","to":"/logi-sisse"}},[_c('v-icon',{staticClass:"pr-2"},[_vm._v("mdi-account")]),_vm._v(" Logi sisse ")],1):_vm._e(),(_vm.isAuthenticated)?_c('v-btn',{staticClass:"float-right white--text text-capitalize",attrs:{"text":"","to":'/profiil'}},[_c('v-icon',{staticClass:"pr-2"},[_vm._v("mdi-account")]),_vm._v(" Minu konto ")],1):_vm._e()],1)],1),(_vm.$route.path !== '/lisa-pilet')?_c('v-row',{staticClass:"mx-auto flex-wrap",staticStyle:{"width":"100%"}},[_c('v-col',{staticClass:"text-left text-sm-right text-col pt-5 pb-0 mb-5"},[_c('div',{staticClass:"display-1 white--text font-italic font-weight-light text-left text-sm-right",style:(_vm.$vuetify.breakpoint.smAndDown ? 'font-size:18px !important' : '')},[_vm._v(" Piletivahetus on imelihtne ")]),_c('v-btn',{staticClass:"white--text mt-3 text-capitalize",staticStyle:{"background":"#003650","border-radius":"10px","height":"auto"},style:(_vm.$vuetify.breakpoint.smAndUp
            ? 'padding:14px 112px;'
            : 'padding:14px 84px;'),attrs:{"text":"","dark":""},on:{"click":function($event){_vm.$router.replace('/lisa-pilet', function () {})}}},[_c('v-icon',{staticStyle:{"position":"absolute","left":"-65px"},attrs:{"left":"","size":"28"}},[_vm._v("mdi-plus")]),_vm._v(" Lisa pilet ")],1)],1)],1):_vm._e(),_c('v-row',{staticClass:"mx-auto",staticStyle:{"width":"100%"}},[_c('v-col',{staticClass:"d-flex align-end col-10"},[_c('div',{staticClass:"headline white--text header-title",staticStyle:{"font-weight":"500"},style:(_vm.$vuetify.breakpoint.smAndDown ? 'font-size:18px !important' : '')},[(
            !_vm.$route.matched.some(function (ref) {
                      var name = ref.name;

                      return name === 'event';
}) &&
              _vm.$route.path !== '/lisa-pilet'
          )?_c('div',[_vm._v(" "+_vm._s(_vm.title())+" ")]):_vm._e(),(_vm.$route.matched.some(function (ref) {
                    var name = ref.name;

                    return name === 'event';
}))?_c('v-btn',{attrs:{"text":"","color":"white"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('v-icon',[_vm._v("mdi-chevron-left")]),_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.general.back'))+" ")],1):_vm._e()],1)]),_c('v-col',{staticClass:"d-flex justify-end text-right col-2 px-0 px-sm-3"},[_c('a',{staticStyle:{"color":"rgba(0,0,0,0)"},attrs:{"href":"https://www.facebook.com/piletivahetus","target":"_blank"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var hover = ref.hover;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"color":hover ? 'blue' : ''},on:{"click":false}},[_vm._v("mdi-facebook")])]}}])})],1),_c('a',{staticStyle:{"color":"rgba(0,0,0,0)"},attrs:{"href":"https://www.instagram.com/piletivahetus","target":"_blank"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var hover = ref.hover;
return [_c('v-icon',{attrs:{"color":hover ? 'purple' : ''},on:{"click":false}},[_vm._v("mdi-instagram")])]}}])})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }