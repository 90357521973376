const monthsEst = [
  'jaan',
  'veebr',
  'märts',
  'apr',
  'mai',
  'juuni',
  'juuli',
  'aug',
  'sept',
  'okt',
  'nov',
  'dets'
]

export default {
  methods: {
    fullDateMonth(date) {
      date = new Date(date)
      return `${date.getDate()}. ${
        monthsEst[date.getMonth()]
      } ${date.getFullYear()}`
    }
  }
}
