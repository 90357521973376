export default {
  chats: [],
  allUserChatParticipants: [],
  participants: [],
  messages: [],
  selectedChat: '',
  observers: [],
  start: null,
  end: null,
  unreadMessagesCount: []
}
