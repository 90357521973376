import GenericDB from './generic-db'
import firestore from './async-firestore'

export default class StripeSourcesDB extends GenericDB {
  constructor(userId) {
    super(`/stripe_customers/${userId}/sources/`)
  }

  // Here you can extend UserPinnedPostsDB with custom methods
  async readAll(constraints = null) {
    const collectionRef = (await firestore()).collection(this.collectionPath)
    let query = collectionRef

    if (constraints) {
      constraints.forEach(constraint => {
        query = query.where(...constraint)
      })
    }

    const formatResult = result =>
      result.docs.map(ref =>
        this.convertObjectTimestampPropertiesToDate({
          id: ref.id,
          ...ref.data()
        })
      )
    return query.get().then(formatResult)
  }
}
