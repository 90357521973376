export default {
  tickets: [],
  userTickets: [],
  userAddedTickets: [],
  // uploadedTickets: [],
  events: [],
  event: {},
  subevents: [],
  posts: [],
  lastVisibleEvent: null,
  pinnedPosts: [],
  userPosts: [],
  orders: [],
  postNameToCreate: '',
  postDeletionPending: [],
  postCreationPending: false,
  showSoldItems: false,
  selectedSortOrder: {
    title: 'Newest',
    value: 'desc',
    sortBy: 'createTimestamp'
  },
  selectedEvent: null,
  stepperStep: 1,
  addedNewEventFromDialog: false,
  addedDateVenueFromDialog: false,
  ticketSalesId: '',
  ticketType: '',
  swapDetails: '',
  ticketSeat: '',
  selectedPrice: 0,
  allowSwap: false,
  sellAsPatch: false
}
