import store from '../store'
import GenericDB from './generic-db'
import firestore from './async-firestore'

// [Optional] Extend GenericDB if you want CRUD operations
export default class TicketsDB extends GenericDB {
  constructor() {
    super('tickets')
  }

  // Here you can extend PostsDB with custom methods
  /**
   * Get all tickets
   * @param {Array} constraints - Array of arrays containing query constraints String, eg. [['xxx', '==', 'xxx']]
   * @param {orderBy} orderBy - Array of strings, eg. ['createTimestamp', 'desc']
   */
  async readAll(constraints = null, orderBy) {
    const collectionRef = (await firestore()).collection(this.collectionPath)
    let query = collectionRef.orderBy(...orderBy)

    if (constraints) {
      constraints.forEach(constraint => {
        query = query.where(...constraint)
      })
    }

    const formatResult = result =>
      result.docs.map(ref =>
        this.convertObjectTimestampPropertiesToDate({
          id: ref.id,
          ...ref.data()
        })
      )

    return query.get().then(formatResult)
  }

  async readMoreTickets(constraints = null) {
    const collectionRef = (await firestore()).collection(this.collectionPath)
    let query = collectionRef
      .orderBy(
        store.state.tickets.selectedSortOrder.sortBy,
        store.state.tickets.selectedSortOrder.value
      )
      .startAfter(store.state.tickets.lastVisiblePost.createTimestamp)
      .limit(10)
    if (constraints) {
      constraints.forEach(constraint => {
        query = query.where(...constraint)
      })
    }

    const formatResult = result =>
      result.docs.map(ref =>
        this.convertObjectTimestampPropertiesToDate({
          id: ref.id,
          ...ref.data()
        })
      )
    return query.get().then(formatResult)
  }

  // helper to get the last document in a QuerySnapShot
  getLastDoc = snap => {
    if (snap.empty) return null
    return snap.docs[snap.docs.length - 1]
  }
}
