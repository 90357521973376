export default {
  methods: {
    shortenTheTitle(title) {
      // eslint-disable-next-line no-nested-ternary
      return title
        ? title.length > 40
          ? `${title.substring(0, 40)}...`
          : title
        : title
    }
  }
}
