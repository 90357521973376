export default {
  chats(state, getters, rootState) {
    const allChats = state.chats
    if (allChats && rootState.authentication.user) {
      return allChats.filter(
        item =>
          item.senderId === rootState.authentication.user.id ||
          item.receiverId === rootState.authentication.user.id
      )
    }
    return []
  },
  participants: state => state.participants,
  selectedChat: state => state.selectedChat,
  messages: state => state.messages,
  filteredMessages: state =>
    state.messages.slice().sort((a, b) => {
      return new Date(a.createTimestamp) - new Date(b.createTimestamp)
    }),
  observers: state => state.observers,
  lastDoc: state => state.lastDoc,
  end: state => state.end,
  unreadMessagesCount(state) {
    const res = []
    state.unreadMessagesCount.forEach(item => {
      res.push(item.count)
    })
    return res.reduce((a, b) => a + b, 0)
  },
  allUserChatParticipants: state => state.allUserChatParticipants
}
