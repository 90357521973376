export default {
  setSnackbar: (state, value) => {
    state.snackbar = value
  },
  setNetworkOnline: (state, value) => {
    state.networkOnLine = value
  },
  setSWRegistrationForNewContent: (state, value) => {
    state.SWRegistrationForNewContent = value
  },
  setShowAddToHomeScreenModalForApple: (state, value) => {
    state.showAddToHomeScreenModalForApple = value
  },
  setRefreshingApp: (state, value) => {
    state.refreshingApp = value
  },
  setLoading: (state, value) => {
    state.loading = value
  },
  setError: (state, value) => {
    state.error = value
  },
  setSelectedCategory: (state, value) => {
    state.selectedCategory = value
  },
  setSelectedProfileMenuItem: (state, value) => {
    state.selectedProfileMenuItem = value
  },
  setSearchString: (state, value) => {
    state.searchString = value
  },
  setSearchBarOpened: (state, value) => {
    state.searchBarOpened = value
  },
  setSelectedLocation: (state, value) => {
    state.selectedLocation = value
  },
  setEthree: (state, eThreeInstance) => {
    state.eThree = eThreeInstance
  }
}
