<template>
  <v-app
    :style="{ background: $vuetify.theme.themes[theme].background }"
    :class="{ 'full-page': ~fullHeightPages.indexOf($route.name) }"
  >
    <Header
      v-if="showHeader()"
      :full="fullHeightPages.indexOf($route.name) > -1 ? true : false"
    />
    <v-content class="pt-7 pb-5 pa-5 pb-md-12 d-flex">
      <v-fade-transition hide-on-leave>
        <router-view />
      </v-fade-transition>
    </v-content>
    <new-content-available-toastr
      v-if="newContentAvailable"
      class="new-content-available-toastr"
      :refreshing-app="refreshingApp"
      @refresh="serviceWorkerSkipWaiting"
    ></new-content-available-toastr>
    <apple-add-to-home-screen-modal
      v-if="showAddToHomeScreenModalForApple"
      class="apple-add-to-home-screen-modal"
      @close="closeAddToHomeScreenModalForApple(false)"
    >
    </apple-add-to-home-screen-modal>
    <v-snackbar v-model="showSnackbar">
      <v-icon v-if="snackbar.type === 'error'" color="error" class="mr-5">
        mdi-alert
      </v-icon>
      <v-icon v-if="snackbar.type !== 'error'" color="success" class="mr-5">
        mdi-check
      </v-icon>
      {{ snackbar.message }}
      <v-btn
        v-if="!snackbar.action"
        text
        color="accent"
        @click.native="showSnackbar = false"
      >
        Sulge
      </v-btn>
      <v-btn v-else text color="accent" @click.native="snackbar.action()">
        {{ snackbar.actionMessage }}
      </v-btn>
    </v-snackbar>
    <!-- <rate-dialog v-model="rateDialog" /> -->
    <Footer
      v-if="
        fullHeightPages.indexOf($route.name) === -1 &&
          $router.currentRoute.name === 'landing'
      "
    />
  </v-app>
</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import WebFontLoader from 'webfontloader'
import Header from '@/components/Header'
import NewContentAvailableToastr from '@/components/NewContentAvailableToastr'
import AppleAddToHomeScreenModal from '@/components/AppleAddToHomeScreenModal'

export default {
  components: {
    Header,
    NewContentAvailableToastr,
    AppleAddToHomeScreenModal,
    Footer: () => import(/* webpackChunkName: "Footer" */ '@/components/Footer')
  },
  data() {
    return {
      rateDialog: true,
      fullHeightPages: [
        'login',
        'add-ticket',
        'register',
        'forgot-password',
        'check-login',
        'NotFound'
      ]
    }
  },
  computed: {
    ...mapGetters('app', ['newContentAvailable', 'loading']),
    ...mapState('app', [
      'showAddToHomeScreenModalForApple',
      'refreshingApp',
      'snackbar'
    ]),
    theme() {
      return this.$vuetify.theme.dark ? 'dark' : 'light'
    },
    showSnackbar: {
      get() {
        return this.snackbar.show
      },
      set(value) {
        return this.$store.commit('app/setSnackbar', {
          show: value,
          type: null,
          message: null
        })
      }
    }
  },
  created() {
    this.$vuetify.lang.current = 'et'
    // this.$vuetify.theme.dark = true
    WebFontLoader.load({
      custom: {
        families: ['Open+Sans:100,300,400,500,600,700,900', 'Dosis:400'],
        urls: [
          'https://fonts.googleapis.com/css?family=Open+Sans:100,300,400,500,600,700,900&display=swap',
          'https://fonts.googleapis.com/css?family=Dosis&display=swap'
        ]
      },
      active: this.setFontLoaded
    })
  },
  methods: {
    ...mapActions('app', [
      'closeAddToHomeScreenModalForApple',
      'serviceWorkerSkipWaiting'
    ]),
    setFontLoaded() {
      this.$emit('font-loaded')
    },
    showHeader() {
      if (
        this.$vuetify.breakpoint.smAndDown &&
        this.$router.currentRoute.name === 'add-ticket'
      ) {
        return false
      }
      return true
    }
  }
}
</script>

<style lang="scss">
@font-face {
  font-family: 'Open Sans', sans-serif;
}
* {
  font-family: 'Open Sans', sans-serif !important;
}
h1 {
  font-family: 'Open Sans', sans-serif;
}
.v-application {
  .headline,
  .display-1,
  .title,
  .text-md-h5 {
    font-family: 'Open Sans', sans-serif !important;
  }

  .text- {
    &h1,
    &h2,
    &h3,
    &h4,
    &h5 {
      font-family: 'Open Sans', sans-serif !important;
    }
  }

  .title {
    font-size: 24px !important;
  }
}
html {
  // position: fixed;
  height: 100vh;
  overflow-x: hidden;
}

body {
  background-color: #fafafa;
  // position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
}

/* Set container max-width */
.row {
  max-width: 1296px;
  width: 100%;
  margin: 0 auto !important;
}

/* this is the container you want to take the visible viewport  */
/* make sure this is top-level in body */
#your-app-container {
  height: 100%;
}

@keyframes shadowPulse {
  0% {
    box-shadow: 4px 4px 20px rgba(1, 92, 137, 0.4) !important;
  }

  100% {
    box-shadow: 4px 4px 20px rgba(1, 92, 137, 0.4) !important;
  }
}
.primary-shadow {
  box-shadow: 4px 4px 20px rgba(1, 92, 137, 0.4) !important;
}
.primary-hover-shadow {
  box-shadow: 0;
  transition: box-shadow 0.3s;
}
.primary-hover-shadow:hover {
  box-shadow: 4px 4px 20px rgba(1, 92, 137, 0.4) !important;
}
.main-border-radius {
  border-radius: 10px !important;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  background-clip: content-box !important;
  box-shadow: 0 0 0 60px rgba(1, 92, 137, 1) inset;
  -webkit-box-shadow: 0 0 0 60px rgba(1, 92, 137, 1) inset;
  -webkit-text-fill-color: #fff;
  margin-top: 4px;
  padding-left: 8px;
}

.v-application.theme--light {
  // background: radial-gradient(
  //   142.83% 142.83% at 5.24% 2.41%,
  //   #ffdfdf 0%,
  //   #eef9ff 100%
  // ) !important;
  background: rgba(255, 255, 255, 1) !important;
}

.v-application.theme--light.full-page {
  background: linear-gradient(95.09deg, #325490 27.95%, #006596 84.06%), #006494 !important;
  -webkit-background-size: cover !important;
  -moz-background-size: cover !important;
  -o-background-size: cover !important;
  background-size: cover !important;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25) !important;
}

.w-100 {
  width: 100% !important;
}

.h-100 {
  height: 100% !important;
}

.font-weight-600 {
  font-weight: 600 !important;
}

.w-auto {
  width: auto !important;
}

.rounded-10 {
  border-radius: 10px !important;
}

.font-18 {
  font-size: 18px !important;
}

.font-28 {
  font-size: 28px !important;
}

.col-20 {
  max-width: 20% !important;
  flex: 0 0 20% !important;
}

@media screen and (max-width: 600px) {
  /* ***************
 * FB on left side 
 ******************/
  /* this is for the circle position */
  .fb_dialog.fb_dialog_advanced {
    left: 18pt;
  }

  /* The following are for the chat box, on display and on hide */
  iframe.fb_customer_chat_bounce_in_v2 {
    left: 9pt;
  }
  iframe.fb_customer_chat_bounce_out_v2 {
    left: 9pt;
  }

  @keyframes fb_bounce_in_v2 {
    0% {
      opacity: 0;
      transform: scale(0, 0);
      transform-origin: bottom left;
    }

    50% {
      transform: scale(1.03, 1.03);
      transform-origin: bottom left;
    }

    100% {
      opacity: 1;
      transform: scale(1, 1);
      transform-origin: bottom left;
    }
  }

  @keyframes fb_bounce_out_v2 {
    0% {
      opacity: 1;
      transform: scale(1, 1);
      transform-origin: bottom left;
    }

    100% {
      opacity: 0;
      transform: scale(0, 0);
      transform-origin: bottom left;
    }
  }
}
</style>
