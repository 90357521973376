import Vue from 'vue'

import Vuetify from 'vuetify/lib'
import et from '@/i18n/et'
import en from '@/i18n/en'
// import 'vuetify/dist/vuetify.min.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
// eslint-disable-next-line import/extensions
import '@mdi/font/css/materialdesignicons.css'

import App from './App.vue'
import router from './router'
import store from './store'
import '@/misc/register-service-worker'
import '@/misc/handle-network-status'
import '@/firebase/init'
import '@/firebase/authentication'
import '@/misc/handle-apple-install-prompt'
import 'pwacompat'

// Social share icons
library.add(faFacebookSquare)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false

const opts = {
  lang: {
    locales: {
      et,
      en
    },
    current: 'et'
  },
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#0184C3',
        secondary: '#615F5F',
        darkBlue: '#015C89',
        accent: '#0184C3',
        error: '#EF9A9A',
        warning: '#CDDC39',
        info: '#2196f3',
        success: '#4caf50',
        background: '#FAFAFA'
      },
      dark: {
        primary: '#263238',
        secondary: '#78909C',
        accent: '#0184C3',
        error: '#EF9A9A',
        warning: '#CDDC39',
        info: '#2196f3',
        success: '#4caf50',
        background: '#231F20'
      }
    }
  }
}

Vue.use(Vuetify)

new Vue({
  vuetify: new Vuetify(opts),
  router,
  store,
  render: h => h(App)
}).$mount('#app')
