<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md4 text-center>
        <loading-overlay
          :active="true"
          :opacity="1"
          :can-cancel="false"
          :is-full-page="true"
          :height="128"
          :width="128"
          :color="'#fff'"
          :loader="'dots'"
          :background-color="'#FAFAFA'"
        />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import { isNil } from 'lodash'

export default {
  components: {
    LoadingOverlay: () =>
      import(/* webpackChunkName: "LoadingOverlay" */ 'vue-loading-overlay')
  },
  computed: mapState('authentication', ['user']),
  watch: {
    user: {
      handler(user) {
        if (user === undefined) return

        if (this.$route.query.redirectUrl === this.$route.path) {
          this.$router.push('/')
        }

        const redirectUrl = isNil(user)
          ? `/logi-sisse?redirectUrl=${this.$route.query.redirectUrl}`
          : this.$route.query.redirectUrl

        this.$router.push(redirectUrl)
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
.loading-title {
  text-align: center;
}
</style>
