import StripeTokensDB from '@/firebase/stripe-tokens-db'
import StripeSourcesDB from '@/firebase/stripe-sources-db'
import firestore from '@/firebase/async-firestore'

export default {
  /**
   * Get all user sources
   */
  getAllUserSources: async ({ commit, rootState }) => {
    commit('app/setError', null, { root: true })
    commit(
      'app/setLoading',
      { type: 'getAllUserSources', state: true },
      { root: true }
    )
    try {
      const allUserSources = await new StripeSourcesDB(
        rootState.authentication.user.id
      ).readAll()
      commit('app/setError', null, { root: true })
      commit('setSources', allUserSources)
      // return allUserSources
      commit(
        'app/setLoading',
        { type: 'getAllUserSources', state: false },
        { root: true }
      )
    } catch (error) {
      commit('app/setError', error, { root: true })
      commit(
        'app/setLoading',
        { type: 'getAllUserSources', state: false },
        { root: true }
      )
      if (process.env.NODE_END !== 'production') {
        commit(
          'app/setSnackbar',
          {
            show: true,
            type: 'error',
            message: `Error while getting sources: ${error.message}`
          },
          { root: true }
        )
      }
    }
  },
  /**
   * Add credit card information to store it for later use
   */
  addNewSource: async ({ commit, rootState }, sourceData) => {
    commit(
      'app/setLoading',
      { type: 'addNewSource', state: true },
      { root: true }
    )
    commit('app/setError', null, { root: true })
    try {
      await new StripeTokensDB(rootState.authentication.user.id).create({
        token: sourceData.token.id
      })
      commit('addNewSource', sourceData.token.card)

      commit('app/setError', null, { root: true })
      commit(
        'app/setLoading',
        { type: 'addNewSource', state: false },
        { root: true }
      )
      commit(
        'app/setSnackbar',
        {
          show: true,
          type: 'success',
          message: 'New card added successfully.'
        },
        { root: true }
      )
    } catch (error) {
      commit('app/setError', error, { root: true })
      commit(
        'app/setLoading',
        { type: 'addNewSource', state: false },
        { root: true }
      )
      commit(
        'app/setSnackbar',
        {
          show: true,
          type: 'error',
          message:
            process.env.NODE_END !== 'production'
              ? `Error while adding source: ${error.message}`
              : 'Something went wrong! Please try again later.'
        },
        { root: true }
      )
    }
  },
  /**
   * Remove credit card
   */
  removeSource: async ({ commit, rootState }, sourceData) => {
    commit('app/setError', null, { root: true })
    commit(
      'app/setLoading',
      { type: 'removeSource', state: true },
      { root: true }
    )
    try {
      // const deletedSource = await new StripeSourcesDB(
      //   rootState.authentication.user.id
      // ).delete(sourceData.id)
      const collectionRef = (await firestore()).collection(
        `/stripe_customers/${rootState.authentication.user.id}/sources/`
      )
      const sourceToDelete = await collectionRef.where(
        'id',
        '==',
        sourceData.id
      )
      await sourceToDelete.get().then(doc => {
        const deletedSource = collectionRef.doc(doc.docs[0].id).delete()
        commit('removeSourceById', deletedSource.id)
        commit(
          'app/setSnackbar',
          {
            show: true,
            type: 'success',
            message: 'Card removed successfully.'
          },
          { root: true }
        )
      })

      commit('app/setError', null, { root: true })
      commit(
        'app/setLoading',
        { type: 'removeSource', state: false },
        { root: true }
      )
    } catch (error) {
      commit('app/setError', error, { root: true })
      commit(
        'app/setLoading',
        { type: 'removeSource', state: false },
        { root: true }
      )
      commit(
        'app/setSnackbar',
        {
          show: true,
          type: 'error',
          message:
            process.env.NODE_END !== 'production'
              ? 'We could not remove the card this time. Please try again later!'
              : `There was an error while removing the source: ${error.message}`
        },
        { root: true }
      )
    }
  }
}
