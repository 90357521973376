import Vue from 'vue'

export default {
  setUser: (state, value) => {
    state.user = value
  },
  updateUser: (state, userObject) => {
    Vue.set(state.user, userObject.key, userObject.value)
  },
  verifyCurrentUserEmail: state => {
    Vue.set(state.user, 'emailVerified', true)
  }
}
