import Vue from 'vue'
import Router from 'vue-router'
import Head from 'vue-head'
import { isNil } from 'lodash'
import { firebase } from '@firebase/app'
import NotFound from '@/views/NotFound'
import CheckLogin from '@/views/CheckLogin'
import Event from '@/views/Event'
import store from '@/store'
import Landing from '@/views/Landing'

Vue.use(Router)

/* If you don't know about VueHead, please refer to https://github.com/ktquez/vue-head */

Vue.use(Head, {
  complement: process.env.VUE_APP_TITLE
})

/* If you don't know about VueRouter, please refer to https://router.vuejs.org/ */

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'landing',
      seoTitle: 'Esileht',
      component: Landing,
      meta: {
        authNotRequired: true
      }
    },
    {
      path: '/kontrolli-logimist',
      name: 'check-login',
      component: CheckLogin,
      meta: {
        authNotRequired: true
      }
    },
    {
      path: '/logi-sisse',
      name: 'login',
      component: () =>
        import(/* webpackChunkName: "client-chunk-login" */ '@/views/Login'),
      meta: {
        authNotRequired: true
      }
    },
    {
      path: '/registreeri',
      name: 'register',
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-register" */ '@/views/Register'
        ),
      meta: {
        authNotRequired: true
      }
    },
    {
      path: '/unustasin-parooli',
      name: 'forgot-password',
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-forgot-password" */ '@/views/ForgotPassword'
        ),
      meta: {
        authNotRequired: true
      }
    },
    {
      path: '/profiil',
      name: 'profile',
      component: () =>
        import(/* webpackChunkName: "client-chunk-profile" */ '@/views/Profile')
    },
    {
      path: '/uritus/:id',
      name: 'event',
      props: true,
      meta: {
        authNotRequired: true
      },
      component: Event
    },
    {
      path: '/lisa-pilet',
      name: 'add-ticket',
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-add-ticket" */ '@/views/AddTicket'
        ),
      props: true,
      meta: {
        authNotRequired: true
      }
    },
    {
      path: '/privaatsuspoliitika',
      name: 'PrivacyPolicy',
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-privacy-policy" */ '@/views/PrivacyPolicy'
        ),
      meta: {
        authNotRequired: true
      }
    },
    {
      path: '*',
      name: 'NotFound',
      component: NotFound,
      meta: {
        authNotRequired: true
      }
    }
  ]
})

/**
 * Handle user redirections
 */
// eslint-disable-next-line consistent-return
router.beforeEach((to, from, next) => {
  if (store.state.app.error) {
    store.commit('app/setError', null)
  }
  if (from.path === '/profiil') {
    store.commit('app/setSelectedProfileMenuItem', '')
    store.commit('chats/setSelectedChat', '')
  }
  if (from.path === '/lisa-pilet') {
    store.commit('events/setTicketSalesId', '')
    store.commit('events/setTicketType', '')
    store.commit('events/setSwapDetails', '')
    store.commit('events/setTicketSeat', '')
    store.commit('events/setSelectedPrice', 0)
    store.commit('events/setAllowSwap', false)
    store.commit('events/setStepperStep', 1)
    store.commit('events/setUserAddedTickets', [])
    store.commit('events/setUploadedTickets', [])
  }
  if (to.path === '/logout') {
    firebase.auth().signOut()
    const path =
      store.state.authentication.user === null
        ? '/logi-sisse'
        : '/kontrolli-logimist'
    return next(`${path}?redirectUrl=/`)
  }
  if (
    !(to.meta && to.meta.authNotRequired) &&
    isNil(store.state.authentication.user)
  ) {
    const path =
      store.state.authentication.user === null
        ? '/logi-sisse'
        : '/kontrolli-logimist'
    return next(`${path}?redirectUrl=${to.path}`)
  }
  next()
})

export default router
