export default {
  appTitle: state => state.appTitle,
  newContentAvailable: state =>
    state.SWRegistrationForNewContent !== null &&
    state.SWRegistrationForNewContent !== undefined,
  loading: state => state.loading,
  error: state => state.error,
  searchBarOpened: state => state.searchBarOpened,
  selectedLocation: state => state.selectedLocation,
  snackbar: state => state.snackbar
}
